import React, { useState } from "react";
import "./JobListings.css";
import Navbar from "../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import JobItem from "../../components/jobItem/JobItem";
import FilterBox from "../../components/filterBox/FilterBox";
import PaginationContainer from "../../components/paginationContainer/PaginationContainer";
import { useGetJobsQuery } from "../../store/query/jobs";

const JobListings = () => {
  const [filters, setFilters] = useState({
    division: null,
    state_id: null,
    city_id: null,
    min: "",
    max: "",
    page: 1,
    date_from: "",
    date_to: "",
  });
  const { data: jobs, isLoading } = useGetJobsQuery(filters);

  const onPageChange = (event, value) => {
    setFilters((prev) => ({ ...prev, page: value }));
  };

  return (
    <>
      <Navbar showSearch filter />

      <div className="jobListings-searchbox">
        <div className="filter-box-big-screen">
          <FilterBox setFilters={setFilters} filters={filters} />
        </div>

        <div className="jobListings-search-filter-results">
          <PaginationContainer
            loading={isLoading}
            data={jobs?.data?.allJob?.data}
            onPageChange={onPageChange}
            page={filters.page}
            total={jobs?.data?.allJobCount ?? 0}
          >
            <div className="inner">
              {jobs?.data?.allJob?.data?.map((job) => {
                return (
                  <Link to={`${job.id}`} key={job.id}>
                    <div className="joblistings-jobitem">
                      <JobItem
                        title={job.title}
                        rate={job.hourly_rate}
                        jobLoc={`${job.city}, ${job.state}`}
                        jobDesc={job.description}
                        jobOrg={job.company_name}
                        jobImg={job.logo}
                        count={500}
                      />
                    </div>
                  </Link>
                );
              })}
            </div>
          </PaginationContainer>
        </div>
      </div>
    </>
  );
};

export default JobListings;

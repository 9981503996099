export default function useChartConfig(props) {
  const { dataType = "ordinal" } = props || {}
  const state = {
    dataType,
    data: [
      {
        label: "series1",
        data: [
          {
            primary: "01",
            secondary: 500,
            likes: 130,
            radius: 120,
          },
          {
            primary: "02",
            secondary: 550,
            likes: 150,
          },
          {
            primary: "03",
            secondary: 450,
            likes: 200,
          },
          {
            primary: "04",
            secondary: 350,
            likes: 250,
          },
          {
            primary: "05",
            secondary: 500,
            likes: 130,
          },
          {
            primary: "06",
            secondary: 550,
            likes: 150,
          },
          {
            primary: "07",
            secondary: 450,
            likes: 200,
          },
          {
            primary: "08",
            secondary: 350,
            likes: 250,
          },
          {
            primary: "09",
            secondary: 500,
            likes: 130,
          },
          {
            primary: "10",
            secondary: 550,
            likes: 150,
          },
          {
            primary: "11",
            secondary: 450,
            likes: 200,
          },
          {
            primary: "12",
            secondary: 350,
            likes: 250,
          },
        ],
      },
      {
        label: "series2",
        data: [
          {
            primary: "01",
            secondary: 500,
            likes: 130,
            radius: 120,
          },
          {
            primary: "02",
            secondary: 550,
            likes: 150,
          },
          {
            primary: "03",
            secondary: 450,
            likes: 200,
          },
          {
            primary: "04",
            secondary: 350,
            likes: 250,
          },
          {
            primary: "05",
            secondary: 500,
            likes: 130,
          },
          {
            primary: "06",
            secondary: 550,
            likes: 150,
          },
          {
            primary: "07",
            secondary: 450,
            likes: 200,
          },
          {
            primary: "08",
            secondary: 350,
            likes: 250,
          },
          {
            primary: "09",
            secondary: 500,
            likes: 130,
          },
          {
            primary: "10",
            secondary: 550,
            likes: 150,
          },
          {
            primary: "11",
            secondary: 450,
            likes: 200,
          },
          {
            primary: "12",
            secondary: 350,
            likes: 250,
          },
        ],
      },
      {
        label: "series3",
        data: [
          {
            primary: "01",
            secondary: 500,
            likes: 130,
            radius: 120,
          },
          {
            primary: "02",
            secondary: 550,
            likes: 150,
          },
          {
            primary: "03",
            secondary: 450,
            likes: 200,
          },
          {
            primary: "04",
            secondary: 350,
            likes: 250,
          },
          {
            primary: "05",
            secondary: 500,
            likes: 130,
          },
          {
            primary: "06",
            secondary: 550,
            likes: 150,
          },
          {
            primary: "07",
            secondary: 450,
            likes: 200,
          },
          {
            primary: "08",
            secondary: 350,
            likes: 250,
          },
          {
            primary: "09",
            secondary: 500,
            likes: 130,
          },
          {
            primary: "10",
            secondary: 550,
            likes: 150,
          },
          {
            primary: "11",
            secondary: 450,
            likes: 200,
          },
          {
            primary: "12",
            secondary: 350,
            likes: 250,
          },
        ],
      },      
    ],
  }

  return {
    ...state,
  };
}

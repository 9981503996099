import React from "react";
import "./ExploreEmployees.css";
import employee from "../../assets/employee.png";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import people from "../../assets/people.png";

const ExploreEmployees = ({ title, input, children, para }) => {
  return (
    <div className="explore-employees-container">
      {/* image/left */}
      <div className="explore-employee-image" data-aos="fade-right">
        <img src={employee} alt="" />
      </div>

      {/* content/right */}
      <div className="explore-employee-content" data-aos="fade-left">
        <h2>{title}</h2>
        <p className="explore-text">
          {para
            ? para
            : "With our team of innovative healthcare practitioners and IT personnels, we could offer a solution tailored to your heathcare facility/organization workforce needs. Kindly enter your email below to get started."}
        </p>

        {input && (
          <div className="explore-employee-search-box">
            <input type="text" placeholder="Enter Email" />
            <Link className="home-box-one-button">
              <button>
                Get Started <FaRegArrowAltCircleRight />
              </button>
            </Link>
          </div>
        )}

        {input && (
          <div className="employee-community">
            <div className="employee-community-img">
              <img src={people} alt="" />
            </div>
            <span className="employee-community-text">
              Join a communty of over 10k healthcare practitioners
            </span>
          </div>
        )}

        {children && children}
      </div>

      {/* content ends */}
    </div>
  );
};

export default ExploreEmployees;

import React, { useState } from "react";
import "./Footer.css";
import Logo from "../../assets/Group.png";
import appStore from "../../assets/appstore.svg";
import playStore from "../../assets/playstore.svg";
import CustomToolTips from "../toolTips/CustomToolTips";
import DashboardModal from "../dashboardModal/DashboardModal";
import zIndex from "@mui/material/styles/zIndex";

const Footer = () => {
  const [appClick, setAppClick] = useState(false);
  const [playClick, setPlayClick] = useState(false);
  const [modal, setModal] = useState("");

  return (
    <div className="footer-container">
      <div className="footer-box-left">
        <div className="footer-logo-header">
          <div className="footer-logo">
            <img src={Logo} alt="footerlogo" />
          </div>

          <div className="footer-sec">
            <span onClick={() => setModal("TERMS")}>Terms and conditions</span>
            <span onClick={() => setModal("POLICY")}>Privacy Policy</span>
            <span onClick={() => setModal("COMMUNITY")}>
              Professional Community Policy
            </span>
          </div>
        </div>
        <span className="footer-rights">
          © 2023 Freelancemedic. All rights reserved
        </span>
      </div>
      <div className="footer-box-right">
        <span>Download App</span>
        <div className="flex-contact">
          <CustomToolTips
            title="Coming soon"
            open={appClick}
            placement="left-start"
          >
            <div
              className="foot-logo-btn"
              onClick={() => setAppClick(!appClick)}
            >
              <img src={appStore} alt="foot-img" />
            </div>
          </CustomToolTips>

          <CustomToolTips
            title="Coming soon"
            open={playClick}
            placement="right-start"
          >
            <div
              className="foot-logo-btn"
              onClick={() => setPlayClick(!playClick)}
            >
              <img src={playStore} alt="foot-img" />
            </div>
          </CustomToolTips>
        </div>
      </div>

      <DashboardModal
        isOpen={!!modal}
        onClose={() => setModal("")}
        customStyle={{
          overflowX: "hidden",
          height: "600px",
        }}
        children={
          modal === "TERMS" ? (
            <div className="signup-modal">
              <h4>
                USER AGREEMENT FOR FREELANCEMEDIC HEALTHCARE PERSONNEL LOCUM
                HIRING PLATFORM{" "}
              </h4>
              <p className="head">1. Introduction </p>
              <p>
                1.1 Agreement Acceptance: This User Agreement ("Agreement") is a
                legally binding contract between FreelanceMedic and the user
                ("User") of the FreelanceMedic healthcare personnel locum hiring
                platform. By accessing or using any of its platforms (Website,
                Mobile Applications, etcetera), the User agrees to be bound by
                the terms and conditions set forth in this Agreement.
              </p>{" "}
              <p>
                1.2 Parties to the Agreement: This Agreement is entered into
                between FreelanceMedic, a business duly registered with the
                Corporate Affairs Commission of Nigeria as well as operating
                under the laws of Nigeria, and the User, an individual or entity
                seeking to utilize the services provided by FreelanceMedic.
              </p>{" "}
              <p>
                1.3 Platform Description and Purpose: FreelanceMedic is an
                online platform that connects healthcare personnel seeking locum
                opportunities with medical facilities(Hospitals, Pharmacies,
                Clinics, Laboratories, Etcetera) in need of temporary staffing.
                The platform facilitates the hiring process/onboarding,
                providing a centralized marketplace for healthcare personnels
                and medical facilities to engage in locum work arrangements.
              </p>{" "}
              <p>
                1.4 Definitions and Interpretation: This section provides
                definitions for key terms used throughout the Agreement to
                ensure clarity and understanding between the parties. It
                clarifies the interpretation of terms used in the context of
                this Agreement.{" "}
              </p>
              <p className="head">2. User Eligibility and Registration</p>
              <p>
                2.1 User Eligibility Requirements: Users must meet the
                eligibility criteria specified by FreelanceMedic to access and
                use the platform. This includes being a licensed healthcare
                professional in Nigeria or any other country FreelanceMedic
                might extend its services as applicable and complying with any
                additional requirements set by FreelanceMedic.
              </p>{" "}
              <p>
                2.2 Registration Process: Users must complete the registration
                process to create an account on FreelanceMedic. The registration
                requires providing accurate and up-to-date personal,
                professional and organizational information (where applicable),
                and Users agree to maintain the accuracy of their account
                information.
              </p>
              <p>
                2.3 User Account Responsibilities: Users are responsible for
                safeguarding their account credentials and ensuring the
                confidentiality of their account information. Users agree to be
                solely responsible for any activity conducted through their
                accounts.
              </p>{" "}
              <p>
                2.4 Account Security and Confidentiality: FreelanceMedic will
                implement reasonable security measures to protect User accounts
                and information. Users are advised to exercise caution in
                sharing their account information and report any suspected
                unauthorized access or security breaches.
              </p>{" "}
              <p>
                2.5 Account Termination and Suspension: FreelanceMedic reserves
                the right to suspend or terminate User accounts for violation of
                this Agreement, non-compliance with applicable laws, or any
                other reason deemed necessary to protect the integrity and
                security of the platform.
              </p>
              <p className="head">3. Services and Obligations</p>
              <p>
                3.1 Platform Services Overview: FreelanceMedic provides a
                platform that enables Users to search for locum opportunities,
                submit applications, communicate with medical facilities through
                its representative, and enter locum work arrangements. The
                platform also allows users to create locum job openings within
                their profession as well as offers additional features and
                services to facilitate a smooth hiring process.
              </p>{" "}
              <p>
                3.2 User Responsibilities and Obligations: Users agree to comply
                with all applicable laws, regulations, and professional
                standards while using the platform. Users are responsible for
                providing accurate and complete information, ensuring the
                legality and quality of their services, and maintaining
                professionalism in their interactions with medical facilities as
                well as peers.
              </p>{" "}
              <p>
                3.3 Compliance with Laws and Regulations: Users must adhere to
                all Nigerian laws, regulations, and guidelines relevant to their
                professional practice and the provision of healthcare services.
                Users must obtain and maintain any necessary licenses,
                certifications, and permissions required by law. This also
                applies to Countries where FreelanceMedic might extend its
                services.
              </p>{" "}
              <p>
                3.4 Availability and Modifications to Services: FreelanceMedic
                strives to provide uninterrupted access to its platform but does
                not guarantee its availability at all times. The platform may
                undergo maintenance or updates, and FreelanceMedic reserves the
                right to modify or discontinue any feature or service without
                prior notice.
              </p>
              <p>
                3.5 Third-Party Services and Links: FreelanceMedic may integrate
                third-party services or include links to external websites.
                Users acknowledge that these third-party services are subject to
                their own terms and conditions, and FreelanceMedic does not
                endorse or assume responsibility for their content or practices.
              </p>
              <p className="head">4. Locum Hiring Process</p>
              <p>
                4.1 Job Listings and Job Application: FreelanceMedic allows
                medical facilities and registered healthcare personnels to post
                locum job listings, and Users can submit applications based on
                their qualifications and availability. Users understand that
                FreelanceMedic does not guarantee job placement and that hiring
                decisions are the sole discretion of the medical facilities or
                individual representative.
              </p>{" "}
              <p>
                4.2 Verification and Credentialing: FreelanceMedic may verify
                User credentials, qualifications, and licenses to ensure the
                accuracy and legitimacy of information provided. Users consent
                to the verification process and agree to cooperate with
                FreelanceMedic in providing necessary documentation.
              </p>{" "}
              <p>
                4.3 Selection and Confirmation of Locum: Medical facilities have
                the right to select a suitable candidate for locum work based on
                their own criteria. If selected, Users agree to confirm their
                availability and comply with the agreed-upon terms and
                conditions for the locum assignment.
              </p>{" "}
              <p>
                4.4 Locum Cancellation and Rescheduling: Users and medical
                facilities may cancel or reschedule locum assignments under
                agreed-upon terms. Users agree to provide reasonable notice and
                cooperate in finding suitable alternatives when necessary.
              </p>{" "}
              <p>
                4.5 Payment and Billing Process: FreelanceMedic facilitates the
                payment process between Users and medical facilities. The
                specific payment terms, rates, and invoicing procedures will be
                agreed upon between the Users and the medical facilities.
                FreelanceMedic may charge a service fee or commission based on
                the successful completion of a locum assignment.
              </p>{" "}
              <p>
                4.6 Feedback and Ratings Users may provide feedback and ratings
                on their locum assignments and their overall experience with
                medical facilities. FreelanceMedic may display this feedback on
                the platform to assist other Users in making informed decisions.
              </p>
              <p className="head">5. User Content and Intellectual Property</p>
              <p>
                5.1 User Content Ownership and Rights: Users retain ownership of
                the content they submit to the platform, such as profile
                information, credentials, and communication with medical
                facilities. By submitting content, Users grant FreelanceMedic a
                non-exclusive, royalty-free license to use, reproduce, and
                display the content as necessary for platform operation and
                promotion.
              </p>
              <p>
                5.2 Platform Content Ownership and Rights: FreelanceMedic
                retains all ownership rights to the platform's content,
                including text, graphics, logos, trademarks, and software. Users
                may not modify, reproduce, distribute, or exploit the platform's
                content without obtaining explicit permission from
                FreelanceMedic.
              </p>{" "}
              <p>
                5.3 Prohibited Content and Activities: Users agree not to
                upload, publish, or transmit any content that is unlawful,
                fraudulent, misleading, defamatory, or violates the rights of
                others. Users must also refrain from engaging in any activities
                that could compromise the platform's security, integrity, or
                performance.
              </p>{" "}
              <p>
                5.4 Copyright Infringement Claims: FreelanceMedic respects the
                intellectual property rights of others and expects Users to do
                the same. If Users believe their copyrighted work has been
                infringed upon, they may submit a notice of infringement to
                FreelanceMedic, who will take appropriate action as required by
                Nigerian law.
              </p>
              <p className="head">6. Privacy and Data Protection</p>
              <p>
                6.1 Data Collection and Use: FreelanceMedic collects and
                processes User data in accordance with applicable Nigerian data
                protection laws. Users acknowledge and consent to the
                collection, use, and processing of their personal and
                professional information by FreelanceMedic for the purposes
                outlined in the Privacy Policy.
              </p>{" "}
              <p>
                6.2 Data Sharing and Third-Party Access FreelanceMedic may share
                User data with third parties, including medical facilities, for
                the purpose of facilitating locum work arrangements. User data
                will be shared only to the extent necessary and in compliance
                with applicable data protection laws. For Nigeria, see Data
                Protection Laws and Regulations Report 2023 Nigeria (iclg.com).
              </p>{" "}
              <p>
                6.3 Data Security Measures: FreelanceMedic will implement
                reasonable security measures to protect User data from
                unauthorized access, disclosure, alteration, or destruction.
                However, Users acknowledge that no data transmission over the
                internet or storage system can be guaranteed to be 100% secure.
              </p>{" "}
              <p>
                6.4 User Communication and Notifications: FreelanceMedic may
                communicate with Users through various channels, including
                email, notifications within the platform, or other electronic
                means. Users agree to receive these communications and
                acknowledge that they may contain important information
                regarding their account, locum assignments, or platform updates.
              </p>{" "}
              <p>
                6.5 Cookies and Tracking Technologies: FreelanceMedic may use
                cookies and tracking technologies to enhance User experience,
                analyze usage patterns, and personalize content. By using the
                platform, Users consent to the use of such technologies in
                accordance with the Cookie Policy.
              </p>
              <p className="head">7. Confidentiality and Non-Disclosure</p>
              <p>
                7.1 Confidential Information Definition The parties recognize
                that during the use of the platform, they may have access to
                confidential and proprietary information. "Confidential
                Information" includes, but is not limited to, user data,
                business strategies, pricing information, and trade secrets.
              </p>{" "}
              <p>
                7.2 Obligations to Protect Confidential Information: Users agree
                to maintain the confidentiality of any Confidential Information
                they receive from FreelanceMedic or other Users. Users shall not
                disclose, reproduce, or use Confidential Information for any
                purpose other than fulfilling their obligations under this
                Agreement, unless required by law.
              </p>{" "}
              <p>
                7.3 Exceptions to Confidentiality: Users' obligations of
                confidentiality shall not apply to information that: (a) is
                publicly available or becomes publicly available without breach
                of this Agreement; (b) is rightfully obtained from a third party
                without obligations of confidentiality; (c) is independently
                developed by the receiving party without reference to the
                Confidential Information; or (d) is required to be disclosed by
                applicable law or court order.
              </p>
              <p className="head">8. Limitation of Liability</p>
              <p>
                8.1 Disclaimer of Warranties: FreelanceMedic provides the
                platform "as is" and does not make any warranties or
                representations regarding its accuracy, reliability, or
                suitability for any particular purpose. Users acknowledge that
                the use of the platform is at their own risk.
              </p>{" "}
              <p>
                8.2 Limitation of Liability: To the extent permitted by Nigerian
                law, FreelanceMedic shall not be liable for any direct,
                indirect, incidental, consequential, or punitive damages arising
                out of or in connection with the use of the platform, even if
                FreelanceMedic has been advised of the possibility of such
                damages.
              </p>{" "}
              <p>
                8.3 Indemnification: Users agree to indemnify and hold
                FreelanceMedic harmless from any claims, damages, losses,
                liabilities, and expenses (including legal fees) arising out of
                or in connection with their use of the platform, violation of
                this Agreement, or infringement of any rights of third parties.
              </p>
              <p className="head">9. Dispute Resolution and Governing Law</p>
              <p>
                9.1 Dispute Resolution Process: Any disputes arising out of or
                relating to this Agreement shall be resolved amicably through
                good-faith negotiations between the parties. If a resolution
                cannot be reached, the parties agree to submit the dispute to
                arbitration in accordance with the arbitration rules of Nigeria.
              </p>
              <p>
                9.2 Governing Law and Jurisdiction: This Agreement shall be
                governed by and construed in accordance with the laws of
                Nigeria. The parties agree that any legal action or proceeding
                arising out of or relating to this Agreement shall be brought
                exclusively in the courts of Nigeria.
              </p>
              <p className="head">10. Termination and Suspension</p>
              <p>
                10.1 Termination by Users: Users may terminate their account and
                this Agreement by providing written notice to FreelanceMedic.
                Termination shall not relieve Users of any obligations or
                liabilities incurred prior to the termination date.
              </p>{" "}
              <p>
                10.2 Termination by the Platform: FreelanceMedic reserves the
                right to terminate User accounts and this Agreement at its
                discretion, without prior notice, in the event of a breach of
                this Agreement, violation of applicable laws, or any conduct
                deemed detrimental to the platform or other Users.
              </p>{" "}
              <p>
                10.3 Consequences of Termination: Upon termination, Users'
                access to the platform and associated services will cease. Users
                shall remain liable for any outstanding obligations or
                liabilities accrued prior to termination.
              </p>
              <p className="head">11. General Provisions</p>{" "}
              <p>
                11.1 Entire Agreement: This Agreement constitutes the entire
                agreement between FreelanceMedic and the User regarding the use
                of the platform and supersedes any prior agreements or
                understandings, whether written or oral.
              </p>{" "}
              <p>
                11.2 Amendments and Modifications: FreelanceMedic may modify or
                update this Agreement from time to time. Users will be notified
                of any material changes, and continued use of the platform after
                such changes shall constitute acceptance of the modified
                Agreement.
              </p>{" "}
              <p>
                11.3 Severability: If any provision of this Agreement is found
                to be invalid, illegal, or unenforceable, the remaining
                provisions shall continue in full force and effect, and the
                invalid provision shall be replaced with a valid provision that
                achieves the intended purpose to the maximum extent possible.
              </p>{" "}
              <p>
                11.4 Waiver: The failure of FreelanceMedic to enforce any
                provision of this Agreement shall not be deemed a waiver of its
                right to enforce such provision in the future.
              </p>{" "}
              <p>
                11.5 Relationship of the Parties: Nothing in this Agreement
                shall be construed as creating a partnership, joint venture,
                agency, or employment relationship between FreelanceMedic and
                the User. Users shall act as independent contractors.
              </p>
              <p>
                11.6 Assignment: Users may not assign or transfer their rights
                or obligations under this Agreement without the prior written
                consent of FreelanceMedic. FreelanceMedic may assign or transfer
                its rights or obligations without User consent.
              </p>{" "}
              <p>
                11.7 Force Majeure: Neither party shall be liable for any
                failure or delay in the performance of its obligations under
                this Agreement due to circumstances beyond its reasonable
                control, including acts of God, natural disasters, wars, or
                governmental actions.
              </p>{" "}
              <p>
                11.8 Notices: All notices and communications regarding this
                Agreement shall be in writing and delivered via email or through
                the platform's messaging system. Notices to FreelanceMedic shall
                be sent to the designated contact information provided by the
                platform.
              </p>{" "}
              <p>
                11.9 Survival of Terms: Any provisions of this Agreement that,
                by their nature, should survive termination or expiration,
                including but not limited to confidentiality obligations,
                limitation of liability, and dispute resolution provisions,
                shall continue to apply.
              </p>{" "}
              <p>
                11.10 Interpretation: The headings in this Agreement are for
                convenience purposes only and shall not affect the
                interpretation or construction of the provisions herein.
              </p>{" "}
              <p>
                11.11 Language: This Agreement is drafted in the English
                language. If any translation of this Agreement is provided, the
                English version shall prevail in case of any inconsistencies or
                disputes.
              </p>
              <p>
                11.12 Electronic Signature: This Agreement may be executed
                electronically, and electronic signatures shall have the same
                legal effect as handwritten signatures.
              </p>{" "}
              <p>
                11.13 Contact Information: Users may contact FreelanceMedic at
                the designated contact information provided on the platform for
                any questions, concerns, or notices related to this Agreement.
              </p>
            </div>
          ) : modal === "COMMUNITY" ? (
            <div className="signup-modal">
              <h4>Freelancemedic Professional Community Policy </h4>
              <p className="head">Purpose</p>
              <p>
                This policy is designed to establish clear guidelines for
                maintaining professionalism, trust, and mutual respect within
                the Freelancemedic platform. It applies to all members,
                including healthcare professionals, employers, and other
                stakeholders who utilize Freelancemedic services. Our goal is to
                create a collaborative and respectful environment that fosters
                professional growth, ethical behavior, and quality healthcare
                delivery.
              </p>{" "}
              <p className="head">1. Professionalism</p>
              <p>
                All interactions on the platform must reflect the highest
                standards of professionalism. This includes communications
                between healthcare professionals, clients, and platform
                administrators. Healthcare professionals must adhere to the
                ethical standards of their profession, ensuring that patient
                care and confidentiality are always prioritized.
              </p>{" "}
              <p className="head">2. Respectful Communication</p>
              <p>
                Treat all members of the community with respect, regardless of
                their role or background. Discriminatory, abusive, or derogatory
                language will not be tolerated. Disputes should be handled
                respectfully, either directly between the parties or with the
                assistance of Freelancemedic’s dispute resolution team.
              </p>{" "}
              <p className="head">3. Confidentiality</p>
              <p>
                All healthcare professionals must always maintain patient
                confidentiality, in compliance with relevant healthcare
                regulations. Sharing private or sensitive information without
                consent is strictly prohibited.
              </p>{" "}
              <p className="head">4. Compliance with Laws and Regulations</p>
              <p>
                All users must comply with the laws and regulations governing
                healthcare practice in their region. Healthcare professionals
                are responsible for ensuring that they hold the necessary
                licenses and certifications to offer services through
                Freelancemedic.
              </p>{" "}
              <p className="head">5. Quality of Service</p>
              <p>
                Healthcare professionals are expected to provide services that
                meet the highest standards of care. Regular feedback mechanisms
                will be in place to ensure the quality and reliability of the
                services provided on the platform.
              </p>{" "}
              <p className="head">6. Fraud and Misrepresentation</p>
              <p>
                Any form of fraud, including misrepresentation of
                qualifications, services, or credentials, will result in
                immediate suspension or termination of the user’s account.
                Clients are also required to provide accurate and truthful
                information when seeking services.
              </p>{" "}
              <p className="head">7. Payments and Fees</p>
              <p>
                All transactions between healthcare professionals and clients
                must be conducted through the Freelancemedic platform to ensure
                transparency and proper documentation. Users are expected to
                follow the agreed-upon terms for payments, including any
                applicable service fees.
              </p>
              <p className="head">8. Feedback and Dispute Resolution</p>
              <p>
                Clients are encouraged to leave constructive feedback to help
                maintain the high quality of service on the platform. In the
                event of a dispute, Freelancemedic will provide a neutral
                platform for resolution, but both parties must engage in good
                faith efforts to resolve the issue.
              </p>
              <p className="head">9. Zero Tolerance for Harassment</p>
              <p>
                Freelancemedic has a zero-tolerance policy for harassment of any
                kind. This includes, but is not limited to, sexual harassment,
                bullying, and discriminatory behavior. Any reported incidents
                will be thoroughly investigated and may result in suspension or
                permanent removal from the platform.
              </p>{" "}
              <p className="head">10. Platform Integrity</p>{" "}
              <p>
                Users must not engage in activities that undermine the integrity
                or security of the Freelancemedic platform, such as hacking,
                spreading malware, or manipulating reviews. Any attempts to
                disrupt the platform’s services or misuse the community
                resources will be met with strict penalties.
              </p>{" "}
              <p className="head">11. Termination and Suspension of Accounts</p>
              <p>
                Freelancemedic reserves the right to suspend or terminate
                accounts that violate any of these community guidelines or the
                platform’s terms of service. Users whose accounts are suspended
                or terminated may appeal the decision through the appropriate
                channels.
              </p>{" "}
              <p className="head">12. Continuous Improvement</p>{" "}
              <p>
                Freelancemedic is committed to continually improving the
                platform based on user feedback and evolving needs in the
                healthcare industry. Users are encouraged to share suggestions
                or report any issues they encounter, helping to ensure the
                community thrives. By adhering to these guidelines, we can
                ensure that Freelancemedic remains a trusted and valuable
                resource for healthcare professionals and clients alike.
              </p>{" "}
            </div>
          ) : (
            <div className="signup-modal">
              <h4 style={{ textAlign: "center" }}>
                Freelancemedic Professional Community Policy{" "}
              </h4>
              <p style={{ textAlign: "center" }}>
                At Freelancemedic, we prioritize the privacy and security of
                your personal data. This Privacy Policy outlines how we collect,
                use, store, and protect your information in accordance with
                relevant data protection laws applicable in most African
                countries, such as the Nigeria Data Protection Regulation
                (NDPR), Kenya Data Protection Act (KDPA), South Africa’s
                Protection of Personal Information Act (POPIA), and other
                similar regulations.
              </p>
              <p className="head">1. Information We Collect</p>{" "}
              <p>
                Freelancemedic collects personal information necessary to
                provide our services effectively. This includes: <br /> Personal
                Identifiable Information (PII): Name, email address, phone
                number, date of birth, gender, address, and identification{" "}
                documents. <br /> Professional Information: Qualifications,
                licenses, certifications, work history, and references. <br />
                Health-Related Data: Health professional interactions, medical
                records, and other data necessary for patient care (where
                applicable). <br /> Financial Information: Bank account details
                and payment information to facilitate transactions. <br />{" "}
                Technical Data: IP addresses, device information, browser types,
                and usage data for platform optimization.
              </p>{" "}
              <p className="head">2. How We Use Your Information</p>{" "}
              <p>
                Freelancemedic processes your personal data for the following
                purposes: <br /> Service Delivery: To match healthcare
                professionals with employers or clients and ensure the seamless
                delivery of medical services. <br /> Account Management: To
                verify your identity, manage your profile, and process
                transactions. <br />
                Communication: To send updates, notifications, and respond to
                inquiries. <br /> Compliance and Legal Obligations: To comply
                with applicable laws and regulations, including licensing
                requirements and contractual obligations. <br /> Data Analytics:
                To monitor, analyze, and improve our services by using
                anonymized data for research, analysis, and reporting.
              </p>{" "}
              <p className="head">3. Data Sharing</p>{" "}
              <p>
                Freelancemedic shares your personal data with third parties only
                in the following situations:
                <br /> Healthcare Employers or Clients: Personal and
                professional information is shared with potential employers or
                clients to facilitate work engagements. <br /> Service
                Providers: We may share your data with trusted third-party
                service providers (such as payment processors or IT providers)
                to support our operations. <br />
                Legal Obligations: We may share your information when required
                to comply with legal obligations, regulatory requests, or court
                orders. <br /> With Your Consent: In instances where additional
                information sharing is required, we will seek your explicit
                consent <br /> We do not sell or rent your personal data to
                third parties for marketing purposes.
              </p>{" "}
              <p className="head">4. Data Security</p>{" "}
              <p>
                Freelancemedic takes the protection of your personal data
                seriously. We have implemented appropriate technical and
                organizational security measures to safeguard your information
                from unauthorized access, alteration, loss, or destruction.
                These measures include:
                <br /> Service Delivery: To match healthcare professionals with
                employers or clients and ensure the seamless delivery of medical
                services. <br /> Encryption: Personal and financial information
                is encrypted to ensure secure storage and transmission.
                <br />
                <br /> Access Control: Only authorized personnel with legitimate
                business needs have access to personal data. <br /> Data
                Minimization: We limit data collection to only what is necessary
                for providing our services.
              </p>{" "}
              <p className="head">5. Data Retention</p>{" "}
              <p>
                We retain your personal information for as long as necessary to
                fulfill the purposes outlined in this policy or as required by
                law. When your data is no longer needed, we will securely delete
                or anonymize it.
              </p>{" "}
              <p className="head">6. Your Rights</p>{" "}
              <p>
                Depending on the data protection laws of your country, you may
                have the following rights: <br /> Access Your Data: Request
                access to the personal information we hold about you. <br />{" "}
                Correct Your Data: Request corrections or updates to your
                personal information if it is inaccurate or incomplete. <br />{" "}
                Delete Your Data: Request deletion of your personal data,
                subject to legal retention requirements. <br /> Withdraw
                Consent: Withdraw your consent for data processing, where
                applicable <br /> Data Portability: Request a copy of your data
                in a commonly used electronic format. <br /> Object to
                Processing: Object to certain types of data processing, such as
                direct marketing or automated decision-making. <br /> To
                exercise any of these rights, please contact us at
                Freelancemedicng@gmail.com
              </p>{" "}
              <p className="head">7. International Data Transfers</p>{" "}
              <p>
                Freelancemedic operates across multiple African countries, and
                your personal data may be transferred and stored in countries
                outside your own. When we transfer your data internationally, we
                ensure that appropriate safeguards, such as standard contractual
                clauses or data protection agreements, are in place to protect
                your privacy.
              </p>{" "}
              <p className="head">8. Cookies and Tracking Technologies</p>{" "}
              <p>
                We use cookies and similar tracking technologies to enhance your
                experience on our platform, analyze website traffic, and deliver
                personalized content. You can control the use of cookies through
                your browser settings. For more information, refer to our Cookie
                Policy.
              </p>{" "}
              <p className="head">9. Changes to this Privacy Policy</p>{" "}
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or legal requirements. Any updates will
                be posted on our website with the effective date indicated. You
                are encouraged to review this policy periodically.
              </p>{" "}
              <p className="head">10. Contact Information</p>{" "}
              <p>
                If you have any questions, concerns, or requests regarding this
                Privacy Policy, please contact us at: <br /> Email:
                Freelancemedicng@gmail.com <br /> By using Freelancemedic, you
                agree to the collection and use of your personal information as
                outlined in this Privacy Policy.
              </p>{" "}
            </div>
          )
        }
      />
    </div>
  );
};

export default Footer;

import React from "react";
import "./ClientSlider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useIsMobile from "../../hook/useIsMobile";
import { useGetTestimonialsQuery } from "../../store/query/testimonials";
import { config } from "../../config";

const ClientSlider = () => {
  const isMobile = useIsMobile();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  const { data } = useGetTestimonialsQuery();
  return (
    <div className="client-slider-container">
      <h3 className="client-speak">Our Clients Speak</h3>

      <Slider {...settings}>
        {data?.data?.all?.map((test) => {
          const sourceImg = `${config.getPublicImages}/${test.image}`;
          return (
            <div className="customer-item" key={test.id}>
              <div className="customer-content-top">
                <p>{test.testimony}</p>
              </div>
              <div className="customer-content-bottom">
                <img src={sourceImg} alt="client" />

                <div className="customer-extra">
                  <h5 className="customer-name">{test.name}</h5>
                  <h5 className="customer-title">{test.company_name}</h5>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default ClientSlider;

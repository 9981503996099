import { api } from "../BaseConfig";

export const locationSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getStates: builder.query({
      query: () => ({
        url: `/states`,
        method: "GET",
      }),
    }),
    getCitiesByState: builder.query({
      query: (params) => ({
        url: `/state-cities/${params.stateId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetStatesQuery, useGetCitiesByStateQuery } = locationSlice;

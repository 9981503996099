import React, { useEffect, useMemo, useState } from "react";
import "./EachJob.css";
import { CiLocationOn } from "react-icons/ci";
import DashboardModal from "../dashboardModal/DashboardModal";
import { config } from "../../config";
import CustomButton from "../customButton/CustomButton";
import TimeSheetIcon from "../icon/TimeSheetIcon";
import StarIcon from "../icon/StarIcon";
import { _currency_format } from "../../utils";
import useRegionLocation from "../../hook/useRegionLocation";

const EachJob = ({ job, status, fillTimeSheetJob, loading }) => {
  const [showTimesheetModal, setShowTimesheetModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [statusState, setStatusState] = useState("");
  const [statusColor, setStatusColor] = useState("#007BFF");
  const { job_info } = job;

  const logo =
    job?.logo?.length > 0
      ? `${config.getPublicImages}/${job?.logo}`
      : `https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg`;

  const btnStyled = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "8px 8px",
    gap: "10px",
  };

  useEffect(() => {
    if (status === 1) {
      setStatusState("APPLIED");
      setStatusColor("#007BFF");
    } else if (status === 2) {
      setStatusState("SHORTLISTED");
      setStatusColor("#28A745");
    } else if (status === 3) {
      setStatusState("REJECTED");
      setStatusColor("#DC3545");
    } else if (status === 6) {
      setStatusState("SAVED");
      setStatusColor("#FFC107");
    }
  }, [status]);

  const { states } = useRegionLocation();

  const state = useMemo(() => {
    return states?.find((i) => Number(i.value) === Number(job_info.state_id));
  }, [states, job_info.state_id]);

  return (
    <>
      <div className="job-box">
        <div className="flex-head">
          <img src={logo} alt="job-img" />

          <div>
            <h3>{job_info?.company_name}</h3>
            <div className="div-locate">
              <CiLocationOn className="job-location-icon" />
              <span className="job-location-text">
                {state?.label}, {job_info.nationality}
              </span>
            </div>
          </div>
        </div>

        <div className="second-section">
          <div className="flex-second">
            <h3>
              {job_info.title.length > 12
                ? job_info.title?.slice(0, 15) + "..."
                : job_info.title}
            </h3>
            <span>N{job_info?.hourly_rate}/hr</span>
          </div>
          <div className="flex-second-dec">
            <p>
              {job_info?.description?.length > 100
                ? job_info?.description?.slice(0, 100) + "..."
                : job_info.description}
            </p>
          </div>
        </div>

        <div className="third-section">
          {status === 2 && (
            <div>
              <CustomButton
                onClick={() => {
                  setShowTimesheetModal(!showTimesheetModal);
                }}
                title={
                  <>
                    {job?.timesheet_status === 1 ? (
                      <div style={btnStyled}>
                        View Sheet <TimeSheetIcon />
                      </div>
                    ) : (
                      <div style={btnStyled}>
                        Fill Sheet <TimeSheetIcon />
                      </div>
                    )}
                  </>
                }
                customStyles={{
                  width: "100%",
                  borderRadius: "5px",
                  height: 40,
                }}
              />
            </div>
          )}

          <div className="left">
            <CustomButton
              variant="outline"
              onClick={() => {
                setShowReviewModal(!showReviewModal);
              }}
              title={
                <div style={btnStyled}>
                  Review <StarIcon />
                </div>
              }
              customStyles={{
                width: "100%",
                borderRadius: "5px",
                height: 40,
              }}
            />
          </div>
        </div>

        <div
          className="status"
          style={{
            backgroundColor: statusColor,
          }}
        >
          <span>{statusState}</span>
        </div>
      </div>

      <DashboardModal
        //onClose={() => setShowTimesheetModal(false)}
        isOpen={showTimesheetModal}
      >
        <FillTimeSheet
          job={job}
          onClose={() => setShowTimesheetModal(false)}
          loading={loading}
          fillTimeSheetJob={fillTimeSheetJob}
        />
      </DashboardModal>

      <DashboardModal isOpen={showReviewModal}>
        <div className="dashboard-modal">
          <h3>Leave a Review of this facility</h3>
          <hr />
          <div className="review-logo">
            <div className="review-logo-img">
              <img src={job.oranizationIMG} alt="img" />
            </div>
            <div className="title-bg">
              <h4>{job.jobTitle}</h4>

              <div className="rate">
                <div>
                  {[1, 2, 3, 4, 5].map((num) => (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M8.52222 0L10.4356 5.88871H16.6273L11.6181 9.52813L13.5315 15.4168L8.52222 11.7774L3.51299 15.4168L5.42634 9.52813L0.417108 5.88871H6.60886L8.52222 0Z"
                        fill="#E6A740"
                      />
                    </svg>
                  ))}
                </div>

                <span>5/5</span>
              </div>
            </div>
          </div>

          {/* experience review box */}
          <div className="experience-review-box">
            <p>Enter Your Experience</p>
            <textarea
              cols="30"
              rows="10"
              placeholder="Please enter your rating and experience of your time at this facility"
            ></textarea>
            {/* <input type="text" placeholder='Please enter your rating and experience of your time at this facility' /> */}
          </div>
          {/* experience review box */}

          {/* cancel and confirm button */}
          <div className="dashboard-modal-btn">
            <button
              onClick={() => setShowReviewModal(!showReviewModal)}
              className="btn-cancel"
            >
              Cancel
            </button>
            <button className="btn-submit">Submit Timesheet</button>
          </div>
          {/* cancel and confirm button */}
        </div>
      </DashboardModal>
    </>
  );
};

export default EachJob;

const FillTimeSheet = ({ job, onClose, fillTimeSheetJob, loading }) => {
  const [selectedSheet, setSelectedSheet] = useState([]);

  const handleRemoveSheet = (id) => {
    const newSelectedSheet = selectedSheet.filter((i) => i.item_id !== id);
    setSelectedSheet(newSelectedSheet);
  };

  return (
    <div className="timesheet-con">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div></div>
        <h3>Timesheet</h3>

        <span onClick={onClose} style={{ fontSize: "20px", fontWeight: 800 }}>
          X
        </span>
      </div>

      <div className="timesheet-headings">
        {job?.timesheet_status === 0 && <span>#</span>}
        <span>Start Date</span>
        <span>End Date</span>
        <span>Start Time</span>
        <span>End Time</span>
        <span>Hours</span>
        <span>Charges</span>
      </div>
      <div className="timesheet-result-box">
        {job?.timesheet?.map((item, i) => (
          <div key={i} className="single-candidate">
            {job?.timesheet_status === 0 && (
              <div className="single">
                <span className="mobile-time">#</span>
                <input
                  type="checkbox"
                  id="mark"
                  name="mark"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedSheet((prev) => [
                        ...prev,
                        { timesheet_id: item.id, status: 1 },
                      ]);
                    } else {
                      handleRemoveSheet(item.id);
                    }
                  }}
                />
              </div>
            )}
            <div className="single">
              <span className="mobile-time">Start Date:</span>
              <p>{item.start_date}</p>
            </div>
            <div className="single">
              <span className="mobile-time">End Date:</span>
              <p>{item.end_date}</p>
            </div>
            <div className="single">
              <span className="mobile-time">Start Time:</span>
              <p>{item.start_time}</p>
            </div>
            <div className="single">
              <span className="mobile-time">End Time:</span>
              <p>{item.end_time}</p>
            </div>
            <div className="single">
              <span className="mobile-time">Hours:</span>
              <p>{item.hour}</p>
            </div>
            <div className="single">
              <span className="mobile-time">Charges:</span>
              <p>{_currency_format(Number(item.charge), "NGN")}</p>
            </div>
          </div>
        ))}
      </div>

      {job?.timesheet_status !== 1 && (
        <div className="timesheet-footer">
          <div className="timesheet-btn">
            <CustomButton
              onClick={() => {
                fillTimeSheetJob({
                  job_id: job.job_info.id,
                  timesheet: [...selectedSheet],
                });
              }}
              loading={loading}
              title="Submit"
              disable={!selectedSheet.length}
              customStyles={{
                width: "100%",
                borderRadius: "5px",
                height: 35,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

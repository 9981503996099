import React from 'react';
import './GoalCard.css';

const GoalCard = ({title, bgColor, icon}) => {

  return (
    <div style={{ backgroundColor: bgColor }} className="goal-card">
      {icon}
      <p>{title}</p>
    </div>
  );
}

export default GoalCard
import React, { memo, useEffect, useState } from "react";
import "./JobActivity.css";
import EachJob from "../../../components/activity/EachJob";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/customButton/CustomButton";
import useApplicationStatus from "../../../hook/useApplicationStatus";
import PaginationContainer from "../../../components/paginationContainer/PaginationContainer";

const JobActivity = () => {
  const { id } = useParams();
  const [status, setStatus] = useState(id ? Number(id) : 1);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { isLoading, jobs, fillTimeSheetJob, isFillTimeSheet } =
    useApplicationStatus();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (status === 1) {
      setData(jobs.applied);
    } else if (status === 2) {
      setData(jobs.shortlisted);
    } else if (status === 3) {
      setData(jobs.rejected);
    } else if (status === 4) {
      setData([]);
    } else if (status === 5) {
      setData([]);
    } else if (status === 6) {
      setData(jobs.saved);
    }
  }, [status, jobs]);

  const itemsPerPage = 10;

  // Calculate the indices of items to show on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //console.log(currentItems);

  return (
    <div className="job-activity-container">
      <div className="job-activity-right">
        <div className="job-activity-right-top">
          <div className="activity-box-head">
            <h2>Job Activity</h2>

            <CustomButton
              customStyles={{
                width: "95px",
                height: "40px",
                marginTop: "0",
                fontSize: "14px",
                borderRadius: "5px",
              }}
              title="Post Job"
              onClick={() => navigate("/postjob")}
            />
          </div>
        </div>

        <div className="job-activity-right-bottom">
          <div className="job-activity-box">
            <div className="job-activity-tabs">
              <span
                onClick={() => setStatus(1)}
                className={status === 1 ? "active" : ""}
              >
                Applied
              </span>
              <span
                onClick={() => setStatus(2)}
                className={status === 2 ? "active" : ""}
              >
                Shortlisted
              </span>
              <span
                onClick={() => setStatus(4)}
                className={status === 4 ? "active" : ""}
              >
                Ongoing
              </span>
              <span
                onClick={() => setStatus(5)}
                className={status === 5 ? "active" : ""}
              >
                Completed
              </span>
              <span
                onClick={() => setStatus(3)}
                className={status === 3 ? "active" : ""}
              >
                Rejected
              </span>
              <span
                onClick={() => setStatus(6)}
                className={status === 6 ? "active" : ""}
              >
                Saved
              </span>
            </div>
            <PaginationContainer
              loading={isLoading}
              data={data}
              onPageChange={onPageChange}
              page={currentPage}
              total={data.length ?? 0}
            >
              <div className="job-activity-content">
                {currentItems?.map((job) => {
                  return (
                    <div key={job.job_id} className="job-activity-jobItem">
                      <EachJob
                        job={job}
                        status={status}
                        fillTimeSheetJob={fillTimeSheetJob}
                        loading={isFillTimeSheet}
                      />
                    </div>
                  );
                })}
              </div>
            </PaginationContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(JobActivity);

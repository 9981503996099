import React from "react";
import "./Goals.css";
import doctors from "../../assets/doctors.png";
import GoalCard from "./GoalCard";
import { PiHandshake } from "react-icons/pi";
import { TbHealthRecognition } from "react-icons/tb";
import { BiSolidTime } from "react-icons/bi";
import { ImCheckboxChecked } from "react-icons/im";

const Goals = () => {
  return (
    <div className="goals-bg">
      <div className="goals-content">
        <h2>Our Goals and Vision Statement</h2>

        <div className="img-cover">
          <img src={doctors} alt="doctors" />
        </div>

        <div className="desc">
          <p>
            Our goals revolve around transforming how locum hiring is conducted
            in the healthcare industry in Africa. We aim to provide a seamless
            and efficient platform that simplifies the process of finding,
            hiring and onboarding qualified healthcare professionals on a
            temporary basis within the shortest time possible and one that
            offers optimal reward to healthcare professionals in return. Our
            vision is to become the go-to destination for medical facilities in
            Africa, enabling them to quickly connect with the right
            professionals within their country of licensure, who possess the
            expertise and passion needed to deliver exceptional patient care. We
            are out to achieve these through:
          </p>
        </div>

        <div className="grid-flex">
          <GoalCard
            icon={
              <ImCheckboxChecked style={{ fontSize: "2rem", color: "white" }} />
            }
            title="Streamlined Hiring Process, Quality and Reliability:"
            bgColor="#F5A656"
          />
          <GoalCard
            icon={
              <TbHealthRecognition
                style={{ fontSize: "2rem", color: "white" }}
              />
            }
            title="Access to a vast pool of healthcare professionals"
            bgColor="#20A4D3"
          />
          <GoalCard
            icon={<BiSolidTime style={{ fontSize: "2rem", color: "white" }} />}
            title="Flexibility and convenience"
            bgColor="#62D8E0"
          />
          <GoalCard
            icon={<PiHandshake style={{ fontSize: "2rem", color: "white" }} />}
            title="Building long-term patnerships"
            bgColor="#36A695"
          />
        </div>
      </div>
    </div>
  );
};

export default Goals;

import React from "react";
import Tooltip from "@mui/material/Tooltip";
import './CustomToolTips.css'

const CustomToolTips = ({ open, title, placement, children }) => {
  return (
    <Tooltip arrow={true} open={open} title={title} placement={placement}>
      {children}
    </Tooltip>
  );
};

export default CustomToolTips;

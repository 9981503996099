import React, { useCallback, useState } from "react";
import { AiOutlineStar } from "react-icons/ai";
import Button from "@mui/material/Button";
import CustomMenu from "../customMenu/CustomMenu";
import CustomButton from "../customButton/CustomButton";
import { _currency_format, isEmpty } from "../../utils";
import { config } from "../../config";
import { Link } from "react-router-dom";
import DashboardModal from "../../components/dashboardModal/DashboardModal";
import {
  useFetchPaymentChannelMutation,
  useMakePaymentMutation,
} from "../../store/query/payments";
import { useTypedSelector } from "../../hook/useTypedSelector";
import { PaystackButton } from "react-paystack";
import CustomDialog from "../customDialog/CustomDialog";
import { toast } from "react-toastify";

const SingleCandidates = ({
  name,
  review,
  status,
  date,
  tag,
  handleAction,
  cv,
  appId,
  updateApplicant,
  filled_timesheet,
  rest,
  applicant_info,
  handleRefetch,
}) => {
  const { user } = useTypedSelector((store) => store.appUser);
  const [fetchPaymentChannel] = useFetchPaymentChannelMutation();
  const [showTimesheetModal, setShowTimesheetModal] = useState(false);
  const file = !isEmpty(cv) && `${config.getPublicImages}/${cv}`;
  const [anchorEl, setAnchorEl] = useState(null);
  const [config2, setConfig2] = useState({
    email: "",
    amount: "",
    publicKey: "",
  });
  const [accept, setAccept] = useState(false);

  const handleClick = (event) => {
    if (status !== "Completed") {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options =
    tag === 3
      ? [
          {
            name: "Complaint",
            onClick: () => {
              handleAction();
            },
          },
          { name: "Completed", onClick: () => {} },
        ]
      : tag === 2
      ? [
          {
            name: "Accept",
            onClick: async () => {
              const res = await fetchPaymentChannel({
                acc_type: user.account_type,
              }).unwrap();
              if (res.status) {
                setConfig2({
                  email: applicant_info.email,
                  //amount: rest.filled_pay * 100,
                  amount: 500,
                  publicKey: res.data.active[0].public_key,
                });
                setAccept(!accept);
              }
            },
          },
          { name: "Reject", onClick: () => {} },
          { name: "Details", onClick: () => {} },
        ]
      : tag === 1
      ? [{ name: "Details", onClick: () => {} }]
      : [
          {
            name: "Shortlist candidate",
            onClick: () =>
              updateApplicant({
                option: "shortlist",
                appId,
              }),
          },
          { name: "Details", onClick: () => {} },
        ];

  const complaintOptions =
    status === "Complaint" ? [{ name: "Resolve", onClick: () => {} }] : options;

  return (
    <div className="single-candidate">
      <div className="single-candidate-left">
        <h5>Name</h5>
        <h5>Review</h5>
        <h5>Status</h5>
        <h5>Date Applied</h5>
        <h5>Action</h5>
      </div>

      <div className="single-candidate-right">
        <span className="name">
          <span className="charAt">{name.charAt(0).toUpperCase()}</span>
          {name}
        </span>
        <span className="single review">
          <AiOutlineStar /> {review}
        </span>
        <span className="single review">{date}</span>
        <div className="status">
          <span
            className={
              status === "Pending"
                ? "pend"
                : status === "Accepted"
                ? "acc"
                : status === "Rejected"
                ? "rej"
                : status === "Complaint"
                ? "rej"
                : status === "Completed"
                ? "succ"
                : status === "Ongoing"
                ? "pend"
                : null
            }
          >
            {status}
          </span>
        </div>

        {tag === 2 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomButton
              variant="outline"
              onClick={() => {
                setShowTimesheetModal(!showTimesheetModal);
              }}
              customStyles={btnStyled}
              title="Timesheet"
              border="#d8dde5"
            />
          </div>
        ) : (
          <Link
            to={file && file}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomButton
              variant="outline"
              customStyles={btnStyled}
              title="View CV"
              border="#d8dde5"
            />
          </Link>
        )}
        <div className="dot-bg">
          <Button onClick={handleClick} disabled={status === "Completed"}>
            <span className="dot">...</span>
          </Button>
        </div>
        <CustomMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          options={complaintOptions}
        />
        <div className="mobile-options">
          {status !== "Completed" &&
            complaintOptions.map((option, index) => (
              <button
                key={index}
                className={`${index === 0 ? "message" : "delete"}`}
                onClick={option.onClick}
              >
                {option.name}
              </button>
            ))}
        </div>
      </div>

      <DashboardModal
        //onClose={() => setShowTimesheetModal(false)}
        isOpen={showTimesheetModal}
      >
        <FillTimeSheet
          filled_timesheet={filled_timesheet}
          onClose={() => setShowTimesheetModal(false)}
          //loading={loading}
        />
      </DashboardModal>

      <CustomDialog open={accept}>
        <AcceptModal
          close={() => setAccept(false)}
          config={config2}
          others={{ ...rest, ...applicant_info, filled_timesheet }}
          handleRefetch={handleRefetch}
        />
      </CustomDialog>
    </div>
  );
};

export default SingleCandidates;

const btnStyled = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  padding: "2px 8px",
  borderRadius: "0px",
  BsBorderWidth: "1px",
  fontSize: "13px",
  height: "30px",
  marginTop: "0px",
};

const FillTimeSheet = ({ filled_timesheet, onClose, loading }) => {
  return (
    <div className="timesheet-con">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div></div>
        <h3>Timesheet</h3>

        <span onClick={onClose} style={{ fontSize: "20px", fontWeight: 800 }}>
          X
        </span>
      </div>

      <div className="timesheet-headings">
        <span>Start Date</span>
        <span>End Date</span>
        <span>Start Time</span>
        <span>End Time</span>
        <span>Hours</span>
        <span>Charges</span>
      </div>
      <div className="timesheet-result-box">
        {filled_timesheet?.map((item, i) => (
          <div key={i} className="single-candidate">
            <div className="single">
              <span className="mobile-time">Start Date:</span>
              <p>{item.start_date}</p>
            </div>
            <div className="single">
              <span className="mobile-time">End Date:</span>
              <p>{item.end_date}</p>
            </div>
            <div className="single">
              <span className="mobile-time">Start Time:</span>
              <p>{item.start_time}</p>
            </div>
            <div className="single">
              <span className="mobile-time">End Time:</span>
              <p>{item.end_time}</p>
            </div>
            <div className="single">
              <span className="mobile-time">Hours:</span>
              <p>{item.hour}</p>
            </div>
            <div className="single">
              <span className="mobile-time">Charges:</span>
              <p>{_currency_format(Number(item.charge), "NGN")}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="timesheet-footer">
        <div className="timesheet-btn">
          <CustomButton
            loading={loading}
            title="Submit"
            customStyles={{
              width: "100%",
              borderRadius: "5px",
              height: 35,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const AcceptModal = ({ close, config, others, handleRefetch }) => {
  const { user } = useTypedSelector((store) => store.appUser);
  const [makePayment] = useMakePaymentMutation();

  const totalHours = others.filled_timesheet.reduce(
    (acc, entry) => acc + parseInt(entry.hour),
    0
  );

  const componentProps = {
    email: config.email,
    amount: config.amount,
    metadata: {
      name: others.name,
    },
    publicKey: config.publicKey,
    onSuccess: () => onSuccess(),
    onClose: () => alert("Wait! You need this oil, don't go!!!!"),
  };

  const onSuccess = useCallback(async () => {
    const payload = {
      job_id: others.job_id,
      applicant_id: others.id,
      applicant_account_type: others.account_type,
      hourly_rate: others.filled_timesheet[0].hourly_rate,
      selected_hour: totalHours,
      job_pay: others.job_pay,
      payment_channel: "paystack",
      payment_status: 1,
      acc_type: user.account_type,
    };

    const res = await makePayment(payload);

    if (res.data.status) {
      toast.success(res.data.message);
      close();
      handleRefetch();
    } else {
      toast.error("Something went wrong");
    }
  }, [handleRefetch, makePayment, close, others, user, totalHours]);

  return (
    <div className="modal-completed">
      <p>Are you sure you want to accept?</p>

      <div className="btn-group-modal" style={{ marginTop: "20px" }}>
        <PaystackButton
          {...componentProps}
          style={{ border: "none", backgroundColor: "red", outline: "none" }}
        >
          <CustomButton
            customStyles={{
              width: "120px",
              height: "40px",
              marginTop: "0",
              fontSize: "14px",
              borderRadius: "5px",
            }}
            title="CONFIRM"
          />
        </PaystackButton>
        <CustomButton
          customStyles={{
            width: "120px",
            height: "40px",
            marginTop: "0",
            fontSize: "14px",
            borderRadius: "5px",
          }}
          border={"#bb2124"}
          background={"#bb2124"}
          title="CANCEL"
          onClick={close}
        />
      </div>
    </div>
  );
};

import React from "react";

const ApplicantsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M7.13949 8.1525C7.06155 8.145 6.96802 8.145 6.88229 8.1525C5.02729 8.0925 3.5542 6.63 3.5542 4.83C3.5542 2.9925 5.09743 1.5 7.01479 1.5C8.92435 1.5 10.4754 2.9925 10.4754 4.83C10.4676 6.63 8.99449 8.0925 7.13949 8.1525Z"
        stroke="#36A695"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7903 3C14.3023 3 15.5182 4.1775 15.5182 5.625C15.5182 7.0425 14.3491 8.1975 12.8916 8.25C12.8293 8.2425 12.7591 8.2425 12.689 8.25"
        stroke="#36A695"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.24251 10.92C1.35634 12.135 1.35634 14.115 3.24251 15.3225C5.3859 16.7025 8.90104 16.7025 11.0444 15.3225C12.9306 14.1075 12.9306 12.1275 11.0444 10.92C8.90884 9.5475 5.39369 9.5475 3.24251 10.92Z"
        stroke="#36A695"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.2944 15C14.8556 14.8875 15.3856 14.67 15.8221 14.3475C17.038 13.47 17.038 12.0225 15.8221 11.145C15.3934 10.83 14.8712 10.62 14.3178 10.5"
        stroke="#36A695"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ApplicantsIcon;

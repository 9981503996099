import React from "react";

const ViewsIcon = ({color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
    >
      <path
        d="M13.0257 8.99994C13.0257 10.4849 11.7786 11.6849 10.2354 11.6849C8.69213 11.6849 7.44507 10.4849 7.44507 8.99994C7.44507 7.51494 8.69213 6.31494 10.2354 6.31494C11.7786 6.31494 13.0257 7.51494 13.0257 8.99994Z"
        stroke={color ? color : "#F5A656"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.2352 15.2025C12.9865 15.2025 15.5508 13.6425 17.3356 10.9425C18.0371 9.88504 18.0371 8.10754 17.3356 7.05004C15.5508 4.35004 12.9865 2.79004 10.2352 2.79004C7.48386 2.79004 4.9196 4.35004 3.13475 7.05004C2.43327 8.10754 2.43327 9.88504 3.13475 10.9425C4.9196 13.6425 7.48386 15.2025 10.2352 15.2025Z"
        stroke={color ? color : "#F5A656"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ViewsIcon;

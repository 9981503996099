import React from "react";
import { Chart } from "react-charts";
import useDemoConfig from "../../../hook/useDemoConfig";

export default function ChartGraph() {
  const { data } = useDemoConfig();

  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum.secondary,
        stacked: true,
      },
    ],
    []
  );

  const getSeriesStyle = React.useCallback((series) => {
    const colorPalette = {
      series1: "#4339F2",
      series2: "#34B53A",
      series3: "#FFB200",
    };

    return {
      fill: colorPalette[series.label]
    };
  }, []);

  return (
    <Chart
      options={{
        data,
        primaryAxis,
        secondaryAxes,
        getSeriesStyle,
      }}
    />
  );
}

import React from "react";
import "./ExploreDivisions.css";
import squareOrange from "../../assets/orange-square.png";
import prescription from "../../assets/explore-d/prescription-pills.png";
import microscope from "../../assets/explore-d/microscope.png";
import medicalCap from "../../assets/explore-d/medical-cap.png";
import xray from "../../assets/explore-d/x-ray.png";
import stethoscope from "../../assets/explore-d/stethoscope.png";
import { useNavigate } from "react-router-dom";

const ExploreDivisions = () => {
  const navigate = useNavigate();
  return (
    <div className="exploreDivisions-container" data-aos="zoom-out">
      <h3>Explore Professional Divisions</h3>
      <p className="exploreDivisions-text">
        Here are a few from a long list of medical professions supported by
        FreelanceMedic. Gain more insight on <span onClick={() => navigate("/register")} style={{fontWeight: 700, textDecoration: 'underline', cursor: 'pointer'}}>sign up</span>
      </p>

      <div className="exploreDivisions-boxes-container">
        <div className="exploreDivisions-box">
          <div className="exploreDivisions-box-icon">
            <img src={prescription} alt="" />
          </div>
          <h5 className="square-green">Pharmacists</h5>
          <p>
            Medication experts; dispensing drugs, offering advice, and ensuring
            safe usage.
          </p>
        </div>
        <div className="exploreDivisions-box">
          <div className="exploreDivisions-box-icon">
            <img src={stethoscope} alt="" />
          </div>
          <h5 className="square-red">Physicians</h5>
          <p>
            Medical experts diagnosing, treating, and caring for patients&#39;
            illnesses and injuries.
          </p>
        </div>
        <div className="exploreDivisions-box">
          <div className="exploreDivisions-box-icon">
            <img src={medicalCap} alt="" />
          </div>
          <h5 className="square-orange">Nurses</h5>
          <p>
            Compassionate caregivers providing patient support and administering
            medical treatments.
          </p>
        </div>
        <div className="exploreDivisions-box">
          <div className="exploreDivisions-box-icon">
            <img src={microscope} alt="" />
          </div>
          <h5 className="square-green">Medical Laboratory Scientist</h5>
          <p>
            Specialists conducting lab tests to aid in disease diagnosis and
            monitoring.
          </p>
        </div>

        <div className="exploreDivisions-box">
          <div className="exploreDivisions-box-icon">
            <img src={xray} alt="" />
          </div>
          <h5 className="square-red">Radiologists</h5>
          <p>
            Imaging specialists interpreting X-rays, MRIs, and CT scans to
            diagnose conditions.
          </p>
        </div>

        <div className="exploreDivisions-box">
          <div className="exploreDivisions-box-icon">
            <img src={squareOrange} alt="" />
          </div>
          <h5 className="square-orange">Sonographers</h5>
          <p>
            Uses ultrasound to create medical images, aiding diagnosis and
            monitoring of patients.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExploreDivisions;

import React, { memo } from "react";
import "./DateFrameInput.css";
import ProfileEditDate from "../../../profileEditDate/ProfileEditDate";

const DateFrameInput = ({ label, values, setValues, error }) => {
  return (
    <div className="timeframe-bg">
      <label>{label}</label>
      <div className="timeframe-input-bg">
        <div style={{ position: "relative" }}>
          <ProfileEditDate
            placeholder="DD-MM-YYY"
            widthCon={"100%"}
            type="date"
            value={values.date_from}
            customStyles={{
              label: {
                color: "#245293",
                fontWeight: 600,
              },
              input: {
                borderRadius: "0px",
                border: `1px solid ${error?.date_from ? "red" : "#000"}`,
              },
            }}
            onChange={(e) => {
              setValues((prev) => ({
                ...prev,
                date_from: e.target.value,
              }));
            }}
          />
          {error?.date_from && (
            <span
              style={{
                fontSize: "12px",
                color: "red",
                position: "absolute",
                top: -8,
                right: 0,
              }}
            >
              {error?.date_from}
            </span>
          )}
        </div>

        <div>to</div>

        <div style={{ position: "relative" }}>
          <ProfileEditDate
            placeholder="DD-MM-YYY"
            widthCon={"100%"}
            type="date"
            value={values.date_to}
            customStyles={{
              label: {
                color: "#245293",
                fontWeight: 600,
              },
              input: {
                borderRadius: "0px",
                border: `1px solid ${error?.date_to ? "red" : "#000"}`,
              },
            }}
            onChange={(e) => {
              setValues((prev) => ({
                ...prev,
                date_to: e.target.value,
              }));
            }}
          />
          {error?.date_to && (
            <span
              style={{
                fontSize: "12px",
                color: "red",
                position: "absolute",
                top: -8,
                right: 0,
              }}
            >
              {error?.date_to}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(DateFrameInput);

import React, { memo } from "react";
import logo from "../../assets/logo-profile.png";
import { BsArrowRight } from "react-icons/bs";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./DashboardDrawer.css";
import useLogout from "../../hook/useLogout";
import { useTypedSelector } from "../../hook/useTypedSelector";
import { config } from "../../config";
import CustomButton from "../customButton/CustomButton";

const DashboardDrawer = ({ navList }) => {
  const navigate = useNavigate()
  const logout = useLogout();
  const { user } = useTypedSelector((store) => store.appUser);
  const profilePics =
    user?.profile_pix && `${config.getPublicImages}/${user?.profile_pix}`;
  const btnStyled = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "8px 25px",
  };

  return (
    <div className="dashboard-Drawer-container">
      <div className="profile-left">
        <div className="upper">
          <Link to="/" className="desktop">
            <div className="logo-img">
              <img src={logo} alt="" />
            </div>
          </Link>

          <CustomButton
            title={
              <>
                <div style={btnStyled}>
                  Find Jobs <BsArrowRight />
                </div>
              </>
            }
            onClick={() => navigate("/shortlist")}
          />

          <div className="dashboard-options">
            <div className="box-1">
              {navList?.map((nav) =>
                nav.hide ? null : (
                  <NavLink
                    key={nav.name}
                    to={nav.to}
                    className={(navClass) =>
                      navClass.isActive ? "profile-active" : "profile-dom"
                    }
                    onClick={() => {
                      if (nav.name === "Logout") logout();
                    }}
                  >
                    <div className="profile-button">
                      {nav.icon}
                      <span>{nav.name}</span>
                    </div>
                  </NavLink>
                )
              )}
            </div>
          </div>
        </div>

        <div className="box-2">
          <div className="first">
            <img src={profilePics} alt="logo" className="profile-pics" />

            <div className="box-2-text">
              <span>{user?.name}</span>
              <h5 className="sec">
                {user?.account_type === "organisation"
                  ? "Org"
                  : user?.division?.name}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(DashboardDrawer);

import React, { memo } from "react";
import './DashboardHeadIcon.css'
import { useTypedSelector } from "../../hook/useTypedSelector";

const DashboardHeadIcon = () => {
  const { user } = useTypedSelector((store) => store.appUser);

  return (
    <div className="menu">
      <div className="widget-box">
        <h4>{user?.name}</h4>
        <div className="bag">
          <span>NB</span>
        </div>
      </div>
    </div>
  );
};

export default memo(DashboardHeadIcon);

import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { errorProps, successProps } from "../utils";
import { toast } from "react-toastify";
import toLower from "lodash/toLower";
import usePublicDivision from "./usePublicDivision";
import useRegionLocation from "./useRegionLocation";
import {
  useCreateJobMutation,
  useUpdateJobMutation,
} from "../store/query/jobs";

const usePostJob = ({ job, id, user }) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [createJob, { isLoading: isCreatingJob }] = useCreateJobMutation();
  const [updateJob, { isLoading: isUpdatingJob }] = useUpdateJobMutation();
  const { divisonList } = usePublicDivision();
  const findSingleDivision = useCallback(() => {
    return divisonList?.find(
      (i) => toLower(i.label) === toLower(job?.division || user?.division?.name)
    );
  }, [divisonList, job?.division, user?.division]);

  const formik = useFormik({
    initialValues: {
      id: "",
      company_name: "",
      contact_email: "",
      phone: "",
      nationality: "Nigeria",
      title: "",
      description: "",
      state: null,
      city: null,
      address: "",
      divisionObj: null,
      hourly_rate: "",
      responsibilities: [],
      requirements: [],
      timesheet: [],
    },
    validateOnMount: true,
    validate: (values) => {
      if (
        values.company_name !== "" &&
        values.contact_email !== "" &&
        values.phone !== "" &&
        values.title !== "" &&
        values.description !== "" &&
        values.hourly_rate !== "" &&
        values.responsibilities?.length !== 0 &&
        values.requirements?.length !== 0 &&
        values.timesheet?.length !== 0 &&
        values.divisionObj !== null &&
        values.state !== null &&
        values.city !== null &&
        values.address !== ""
      ) {
        setCanSubmit(true);
      } else {
        setCanSubmit(false);
      }
    },
    onSubmit: (values) => {
      const { divisionObj, state, city, id, ...data } = values;
      const body = {
        division_id: divisionObj.value,
        state_id: state.value,
        city_id: city.value,
        type: user?.account_type,
        ...data,
      };
      if (id.length === 0) {
        //console.log("onsubmit", body);
        handleSubmit(body);
      } else {
        //console.log("update", id, body);
        handleUpdate(id, body);
      }
    },
  });

  const { states, cities, setStateId } = useRegionLocation();

  const { setValues, values } = formik;

  const selectedProvinceOption =
    useMemo(() => {
      return states?.find((i) => toLower(i.label) === toLower(job?.state));
    }, [states, job?.state]) || values?.state;

  const cityOption = useMemo(() => {
    return cities?.find(
      (i) => toLower(i.label) === toLower(job?.city || values.city?.name)
    );
  }, [cities, job?.city, values.city]);

  const state = selectedProvinceOption;
  const city = cityOption;

  useEffect(() => {
    setStateId(state?.value);
  }, [state?.value, setStateId]);

  useEffect(() => {
    if (id) {
      setValues({
        id: job?.id,
        company_name: job?.company_name,
        contact_email: job?.contact_email,
        phone: job?.phone,
        nationality: "Nigeria",
        title: job?.title,
        description: job?.description,
        divisionObj: findSingleDivision(),
        state: selectedProvinceOption,
        city: cityOption,
        address: job?.address,
        hourly_rate: job?.hourly_rate,
        responsibilities: job?.responsibilities || [],
        requirements: job?.requirements || [],
        timesheet: job?.timesheet || [],
      });
    }
  }, [id, job, selectedProvinceOption, cityOption]);

  const handleUpdate = async (id, body) => {
    const values = {
      ...body,
      id,
      _method: "PUT",
    };

    const res = await updateJob(values);

    if (res?.data?.status) {
      toast.success("Job Updated Successfully", successProps);
    } else {
      toast.error("Failed to update", errorProps);
    }
  };

  const handleSubmit = async (values) => {
    const res = await createJob(values);

    if ("data" in res) {
      const { data } = res;
      if (data.status === true) {
        toast.success(data?.message, successProps);
        formik.handleReset();
      }
    } else {
      toast.error("Failed to Create Job", errorProps);
    }
  };

  return {
    ...formik,
    canSubmit,
    loading: isCreatingJob || isUpdatingJob,
    states,
    cities,
    state,
    city,
  };
};

export default usePostJob;

import { components } from "react-select";
import get from "lodash/fp/get";
import "../CustomAutoComplete.css";
import { memo, useEffect } from "react";

const CustomSingleValue = ({ csvProps, otherValue }) => {
  useEffect(() => {
    if (otherValue === "" || otherValue === null) {
      csvProps.clearValue();
    }
  }, [otherValue, csvProps]);
  const { SingleValue } = components;
  const startAdornment = get(["data", "startAdornment"], csvProps);
  const label = get(["data", "label"], csvProps);
  return (
    <SingleValue {...csvProps}>
      <div className="options-body">
        {startAdornment && (
          <div className="startAdornment">{startAdornment}</div>
        )}
        <span>{label}</span>
      </div>
    </SingleValue>
  );
};

export default memo(CustomSingleValue);

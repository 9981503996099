import React, { useState } from "react";
import "./PostedJobs.css";
import JobItem from "../../../components/jobItem/JobItem";
import { useNavigate } from "react-router-dom";
import PaginationContainer from "../../../components/paginationContainer/PaginationContainer";
import { useGetPostedJobsQuery } from "../../../store/query/jobs";
import { useTypedSelector } from "../../../hook/useTypedSelector";

const PostedJobs = () => {
  const navigate = useNavigate();
  const { user } = useTypedSelector((store) => store.appUser);
  const [filters, setFilters] = useState({
    page: 1,
    type: user?.account_type,
  });

  const { data: postedJobs, isLoading } = useGetPostedJobsQuery(filters);

  const onPageChange = (event, value) => {
    setFilters((prev) => ({ ...prev, page: value }));
  };

  return (
    <div className="Posted-jobs-container">
      <div className="posted-jobs-right">
        <h2>Posted Jobs</h2>
        <div className="posted-jobs-content">
          <PaginationContainer
            loading={isLoading}
            data={postedJobs?.data?.allJob}
            onPageChange={onPageChange}
            page={filters.page}
            total={postedJobs?.data?.allJobCount ?? 0}
          >
            <div className="inner-content">
              {postedJobs?.data?.allJob?.data?.map((job) => {
                return (
                  <div className="postJobs-jobItem" key={job.id}>
                    <JobItem
                      id={job.id}
                      title={job.title}
                      rate={job.hourly_rate}
                      jobLoc={`${job.city}, ${job.state}`}
                      jobDesc={job.description}
                      jobOrg={job.company_name}
                      jobImg={job.logo}
                      applicants={() => navigate(`/applicants/${job.id}`)}
                      view={() => navigate(`/joblistings/${job.id}/posted`)}
                    />
                  </div>
                );
              })}
            </div>
          </PaginationContainer>
        </div>
      </div>
    </div>
  );
};

export default PostedJobs;

import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "./useTypedSelector";
import { toast } from "react-toastify";
import {
  useGetApplicantsSingleJobQuery,
  useUpdateAppSingleJobMutation,
} from "../store/query/applicants";

const useManageApplicants = () => {
  const { id } = useParams();
  const { user } = useTypedSelector((store) => store.appUser);
  const [applicants, setApplicants] = useState({
    applied: [],
    shortlisted: [],
    updatedTimeSheet: [],
    onGoing: [],
  });
  const { data, isLoading, refetch } = useGetApplicantsSingleJobQuery({
    id,
    type: user?.account_type,
  });
  const [updateAppSingleJob, { isLoading: isUpdating }] =
    useUpdateAppSingleJobMutation();

  const updateApplicant = useCallback(
    async ({ option, appId }) => {
      const values = {
        job_id: id,
        _method: "PATCH",
      };
      const res = await updateAppSingleJob({
        values,
        option,
        type: user?.account_type,
        appId,
      });

      if (res.data.status) {
        refetch();
        toast.success(res.data.message);
      } else {
        toast.error("Failed to Update Applicant");
      }
    },
    [id, updateAppSingleJob, user?.account_type, refetch]
  );

  useEffect(() => {
    if (data?.status) {
      setApplicants({
        applied: data?.data?.allAppliedApplicant,
        shortlisted: data?.data?.allShortlistedApplicant,
        updatedTimeSheet: data?.data?.allUpdatedTimesheet,
        onGoing: data?.data?.allOngoingApplicant,
      });
    } else {
      setApplicants({
        applied: [],
        shortlisted: [],
        updatedTimeSheet: [],
        onGoing: [],
      });
    }
  }, [data]);

  return {
    applicants,
    loading: isLoading || isUpdating,
    updateApplicant,
    refetch,
  };
};

export default useManageApplicants;

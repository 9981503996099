import img1 from "../../assets/red-square.png"

export const jobsArray = [
    {
        Id: 1,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "Lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "pharmacists",
        available: true,
        status: 'APPROVED'
    },
    {
        Id: 2,
        jobTitle: "locum physicians",
        rate: 350,
        location: "Lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "kwara, Nigeria",
        jobType: "physicians",
        available: true,
        status: 'APPROVED'
    },
    {
        Id: 3,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "ekiti, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "ekiti, Nigeria",
        jobType: "pharmacists",
        available: true,
        status: 'APPROVED'
    },
    {
        Id: 4,
        jobTitle: "locum physicians",
        rate: 350,
        location: "kwara, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "physicians",
        available: false,
        status: 'APPROVED'
    },
    {
        Id: 5,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "abia, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "pharmacists",
        available: true,
        status: 'APPROVED'
    },
    {
        Id: 6,
        jobTitle: "locum physicians",
        rate: 350,
        location: "kaduna, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "physicians",
        available: false,
        status: 'APPROVED'
    },
    {
        Id: 7,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "bayelsa, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "pharmacists",
        available: true,
        status: 'PENDING'
    },
    {
        Id: 8,
        jobTitle: "locum physicians",
        rate: 350,
        location: "Lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "physicians",
        available: true,
        status: 'PENDING'
    },
    {
        Id: 9,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "bayelsa, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "pharmacists",
        available: false,
        status: 'PENDING'
    },
    {
        Id: 10,
        jobTitle: "locum physicians",
        rate: 350,
        location: "abia, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "physicians",
        status: 'PENDING'
    },
    {
        Id: 11,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "kwara, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "pharmacists",
        status: 'PENDING'
    },
    {
        Id: 12,
        jobTitle: "locum physicians",
        rate: 350,
        location: "ekiti, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "physicians",
        status: 'PENDING'
    },
    {
        Id: 13,
        jobTitle: "locum physicians",
        rate: 350,
        location: "bayelsa, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "physicians",
        status: 'REJECTED'
    },
    {
        Id: 14,
        jobTitle: "locum physicians",
        rate: 350,
        location: "lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "physicians",
        status: 'REJECTED'
    },
    {
        Id: 15,
        jobTitle: "locum physicians",
        rate: 350,
        location: "kaduna, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "physicians",
        status: 'REJECTED'
    },
    {
        Id: 16,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "kwara, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "pharmacists",
        status: 'REJECTED'
    },
    {
        Id: 17,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "pharmacists",
        status: 'APPLIED'
    },
    {
        Id: 18,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "pharmacists",
        status: 'APPLIED'
    },
    {
        Id: 19,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "bayelsa, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "pharmacists",
        status: 'APPLIED'
    },
    {
        Id: 20,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "ekiti, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "pharmacists",
        status: 'REJECTED'
    },
    {
        Id: 21,
        jobTitle: "locum physicians",
        rate: 350,
        location: "kaduna, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "physicians",
        status: 'REJECTED'
    },
    {
        Id: 22,
        jobTitle: "locum physicians",
        rate: 350,
        location: "kaduna, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "physicians",
        status: 'REJECTED'
    },
    {
        Id: 23,
        jobTitle: "locum physicians",
        rate: 350,
        location: "kwara, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "physicians",
        status: 'SAVED'
    },
    {
        Id: 24,
        jobTitle: "locum physicians",
        rate: 350,
        location: "bayelsa, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "physicians",
        status: 'SAVED'
    },
    {
        Id: 25,
        jobTitle: "locum physicians",
        rate: 350,
        location: "lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "physicians",
        status: 'SAVED'
    },
    {
        Id: 26,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "Lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "nurses",
        available: true,
        status: 'APPROVED'
    },
    {
        Id: 27,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "Lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "nurses",
        available: true,
        status: 'APPROVED'
    },
    {
        Id: 28,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "Lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "nurses",
        available: true,
        status: 'APPROVED'
    },
    {
        Id: 29,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "Lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "lab",
        available: true,
        status: 'APPROVED'
    },
    {
        Id: 30,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "Lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "lab",
        available: true,
        status: 'APPROVED'
    },
    {
        Id: 31,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "Lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "lab",
        available: true,
        status: 'APPROVED'
    },
]


export const jobsArray2 = [
    {
        Id: 1,
        jobTitle: "locum pharmacists",
        rate: 350,
        location: "Lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "Lagos, Nigeria",
        jobType: "pharmacists"
    },
    {
        Id: 2,
        jobTitle: "locum physicians",
        rate: 350,
        location: "Lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "kwara, Nigeria",
        jobType: "pharmacists"
    },
    {
        Id: 3,

        jobTitle: "locum pharmacists",
        rate: 350,
        location: "Lagos, Nigeria",
        des: "get connected with top-rated medical facilities acros the country, with a regularly updated database, so you can find the right assignment",
        organization: "medplus phamarcy",
        oranizationIMG: img1,
        organizationLocation: "ekiti, Nigeria"
    },
]



// array for dashboard applications
// array for dashboard applications
export const applicationsArray = [
    {
        hospitalName: "Life Hospital",
        hospitalLocation: "Lagos state",
        hospitalImg: img1,
        status: "Pending Application",
        date: "12-SEP-23",
        time: "09:04"
    },
    {
        hospitalName: "Alpha Phamarcy",
        hospitalLocation: "Ogun state",
        hospitalImg: img1,
        status: "Successful",
        date: "12-MARCH-23",
        time: "11:34"
    },
    {
        hospitalName: "Life Hospital",
        hospitalLocation: "Oyo state",
        hospitalImg: img1,
        status: "Pending Interview",
        date: "6-JUNE-23",
        time: "19:25"
    },
    {
        hospitalName: "Emzor pharmaceuticals",
        hospitalLocation: "Ekiti state",
        hospitalImg: img1,
        status: "Failed",
        date: "12-MARCH-23",
        time: "16:04"
    }
]
// array for dashboard applications
// array for dashboard applications
import { api } from "../BaseConfig";

export const authSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    auth: builder.query({
      query: (params) => ({
        url: `/${params.acc_type}/view-${params.acc_type}`,
        method: "GET",
        params,
      }),
    }),
    loginUser: builder.mutation({
      query: (data) => ({
        url: `/login`,
        method: "POST",
        body: data,
      }),
    }),
    registerUser: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append("account_type", data.account_type);
        formData.append("confirm_password", data.confirm_password);
        formData.append("division", data.division);
        formData.append("email", data.email);
        formData.append("license", data.license);
        formData.append("name", data.name);
        formData.append("password", data.password);
        formData.append("phone", data.phone);
        return {
          url: `/register`,
          method: "POST",
          body: formData,
        };
      },
    }),
    forgetPassword: builder.mutation({
      query: (data) => ({
        url: `/forgot-password`,
        method: "POST",
        body: data,
      }),
    }),
    updateUser: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        const keys = [
          "name",
          "email",
          "about_us",
          "address",
          "license",
          "career_city",
          "career_state",
          "phone",
          "operation_city",
          "operation_state",
          "organisation_role_id",
          "city",
          "state",
          "country",
          "cv",
          "date_of_birth",
          "division_id",
          "postal_code",
          "profile_pix",
          "_method",
        ];
        keys.forEach((key) => {
          if (data[key] !== undefined && data[key] !== null) {
            formData.append(key, data[key]);
          }
        });
        data.skills.forEach((skill) => {
          formData.append("skills[]", skill);
        });
        return {
          url: `/${data.type}/update-profile`,
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useAuthQuery,
  useLazyAuthQuery,
  useLoginUserMutation,
  useForgetPasswordMutation,
  useRegisterUserMutation,
  useUpdateUserMutation,
} = authSlice;

import React, { useRef } from "react";
import "./DashboardNavbarMobile.css";
import Logo from "../../assets/Group.png";
import { FaBars, FaTimes } from "react-icons/fa";
import useLogout from "../../hook/useLogout";
import { Link, NavLink, useNavigate } from "react-router-dom";
import CustomButton from "../customButton/CustomButton";
import { BsArrowRight } from "react-icons/bs";
import { useTypedSelector } from "../../hook/useTypedSelector";
import { config } from "../../config";

const DashboardNavbarMobile = ({ navList }) => {
  const navRef = useRef();
  const navigate = useNavigate();
  const logout = useLogout();
  const user = useTypedSelector((store) => store.user);
  const profilePics =
    user?.profile_pix && `${config.getPublicImages}/${user?.profile_pix}`;

  const toggleNavbar = () => {
    navRef.current.classList.toggle("responsive_nav_das");
  };

  const btnStyled = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "8px 25px",
  };

  return (
    <div className="bg-nav-mobile">
      <div className="nav-container-das">
        <div className="header-das">
          <Link to="/" className="nav_title">
            <img src={Logo} alt="logo-freelancemedic" />
          </Link>

          <nav ref={navRef}>
            <div className="nav-bar-con">
              <div className="first-items">
                {navList?.map((nav) =>
                  nav.hide ? null : (
                    <NavLink
                      key={nav.name}
                      to={nav.to}
                      className={(navClass) =>
                        navClass.isActive ? "profile-active" : "profile-dom"
                      }
                      onClick={() => {
                        if (nav.name === "Logout") logout();
                        toggleNavbar();
                      }}
                    >
                      <div className="profile-button">
                        {nav.icon}
                        <span>{nav.name}</span>
                      </div>
                    </NavLink>
                  )
                )}
              </div>

              <CustomButton
                title={
                  <>
                    <div style={btnStyled}>
                      Find Jobs <BsArrowRight />
                    </div>
                  </>
                }
                onClick={() => {
                  navigate("/shortlist");
                  toggleNavbar()
                }}
              />

              <div className="box-2">
                <div className="first">
                  <img src={profilePics} alt="logo" className="profile-pics" />

                  <div className="box-2-text">
                    <span>{user?.name}</span>
                    <h5>
                      {user?.account_type === "organisation"
                        ? "Org"
                        : user?.division?.name}
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <button className="nav-btn nav-close-btn" onClick={toggleNavbar}>
              <FaTimes />
            </button>
          </nav>

          <div>
            <button className="nav-btn" onClick={toggleNavbar}>
              <FaBars />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardNavbarMobile;

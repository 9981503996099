import React, { memo, useEffect, useState } from "react";
import Select from "react-select";
import getOr from "lodash/fp/getOr";
import get from "lodash/fp/get";
import CustomOption from "./components/CustomOption";
import "./CustomAutoComplete.css";
import CustomSingleValue from "./components/CustomSingleValue";

const CustomAutoComplete = ({
  initOption,
  otherValue,
  options,
  placeholder,
  label,
  customStyles,
  menuPlacement,
  isClearable,
  isSearchable,
  overrideSelectProps,
  customLabelStyles,
  onChange,
  disabled,
  menuHeight = 300,
}) => {
  const [value, setValue] = useState();

  const handleChange = (e) => {
    setValue(e);
    onChange(e);
  };

  useEffect(() => {
    if (get("value", initOption)) {
      setValue(initOption);
    }
  }, [initOption]);

  useEffect(() => {
    if (otherValue === "" || otherValue === null) {
      setValue();
      handleChange();
    }
  }, [otherValue]);

  const getBoxShadow = ({ state }) => {
    const { isFocused } = state;
    if (isFocused) return "0 0 0 1px #efefef";
    // if (error && touched) return `0 0 0 1px ${theme.palette.error.main}`
    return "0 0 0 1px #efefef";
  };

  const styles = {
    control: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        border: "none",
        "&:hover": {
          border: "none",
        },
        width: "100%",
        borderRadius: "100px",
        boxShadow: getBoxShadow({ state }),
        transition: "box-shadow ease 0.25s",
        paddingLeft: "3px",
        margin: 0,
        outline: "none",
        ...getOr({}, "control", customStyles),
      };
    },
    valueContainer: (defaultStyles) => {
      return {
        ...defaultStyles,
        paddingTop: "7px",
        paddingBottom: "7px",
        paddingLeft: 14,
        margin: 0,
        ...getOr({}, "valueContainer", customStyles),
      };
    },
    menu: (defaultStyles) => {
      return {
        ...defaultStyles,
        minWidth: 220,
        zIndex: 2,
      };
    },
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontFamily: "Noto Sans HK, sans-serif",
        ...getOr({}, "placeholder", customStyles),
      };
    },
    noOptionsMessage: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontFamily: "Noto Sans HK, sans-serif",
        fontSize: 14,
        ...getOr({}, "noOptionsMessage", customStyles),
      };
    },
    loadingMessage: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontFamily: "Noto Sans HK, sans-serif",
        fontSize: 14,
        ...getOr({}, "loadingMessage", customStyles),
      };
    },
    singleValue: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontFamily: "Noto Sans HK, sans-serif",
        fontSize: 14,
        ...getOr({}, "singleValue", customStyles),
      };
    },
    option: (provided, state) => {
      let selectedStyles = {};
      if (state.isSelected) {
        selectedStyles = {
          backgroundColor: "white",
          color: "blue",
        };
      }

      return {
        ...provided,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontFamily: "Noto Sans HK, sans-serif",
        fontSize: 14,
        ...getOr({}, "option", customStyles),
        ...selectedStyles,
      };
    },
  };

  return (
    <div className="customautocomplete">
      {label && (
        <label
          style={{
            color: "#777",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
            ...customLabelStyles,
          }}
        >
          {label}
        </label>
      )}
      <Select
        value={value}
        options={options}
        onChange={handleChange}
        menuPlacement={menuPlacement}
        maxMenuHeight={menuHeight}
        noOptionsMessage={() => "no_options"}
        isClearable={isClearable}
        isSearchable={isSearchable}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: null,
          SingleValue: (csvProps) => (
            <CustomSingleValue csvProps={csvProps} otherValue={otherValue} />
          ),
          Option: CustomOption,
        }}
        styles={styles}
        isDisabled={disabled}
        {...overrideSelectProps}
      />
    </div>
  );
};

export default memo(CustomAutoComplete);

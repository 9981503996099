import React from "react";

const BackIcon = ({onBack}) => {
  return (
    <div style={{cursor: 'pointer'}} onClick={onBack}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g mask="url(#mask0_1309_12731)">
          <path
            d="M9.26946 18.6827L10.3233 17.6288L5.44441 12.75H21.606V11.25H5.44441L10.3233 6.37115L9.26946 5.31732L2.58678 12L9.26946 18.6827Z"
            fill="#1C1B1F"
          />
        </g>
      </svg>
    </div>
  );
};

export default BackIcon;

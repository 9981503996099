import React from "react";
import "./AboutWhy.css";
import yellowone from "../../assets/yellowone.png";
import yellowtwo from "../../assets/yellowtwo.png";
import peoplehero from "../../assets/peoplehero.png";
import bgpeoplehero from "../../assets/bgpeoplehero.png";

const AboutWhy = () => {
  return (
    <div className="aboutwhy-body">
      <div className="aboutwhy-container">
        <div className="first" data-aos="fade-right">
          <div className="header">
            <h2>Why Freelancemedic?</h2>

            <div className="first-para">
              <p>
                We understand the critical importance of having qualified and
                reliable healthcare professionals to fill temporary staffing
                gaps in medical facilities/outlets as well as human capacity for
                healthcare fieldworks.
              </p>

              <p>
                Our mission is to bridge the gap between medical facilities in
                need and talented healthcare professionals seeking locum
                opportunities through technology and innovations.{" "}
              </p>
            </div>

            <img className="yellowone" src={yellowone} alt="yellowone" />
            <img className="yellowtwo" src={yellowtwo} alt="yellowtwo" />
          </div>
        </div>

        <div className="second" data-aos="fade-left">
          <img className="peoplehero" src={peoplehero} alt="peoplehero" />
          <img className="bgpeoplehero" src={bgpeoplehero} alt="bgpeoplehero" />
        </div>
      </div>
    </div>
  );
};

export default AboutWhy;

import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const CustomMenu = ({ anchorEl, options, handleClose }) => {

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {options?.map((option, index) => (
        <MenuItem key={index} onClick={option.onClick}>
          {option.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default CustomMenu;

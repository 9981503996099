import React, { memo } from "react";
import "./ModalBtn.css";

const ModalBtn = ({ title, handleClick, customStyles }) => {
  return (
    <button
      style={{ ...customStyles }}
      className="modal-btn-own"
      onClick={handleClick}
    >
      {title}
    </button>
  );
};

export default memo(ModalBtn);

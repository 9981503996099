import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { errorProps, successProps } from "../utils";
import { register } from "../store/slices/user";
import { useDispatch } from "react-redux";
import { useRegisterUserMutation } from "../store/query/auth";
import useLogin from "./useLogin";

const useSignUp = () => {
  const dispatch = useDispatch();
  const [registerUser, { isLoading }] = useRegisterUserMutation();
  const login = useLogin();

  const loading = isLoading 

  const handleSubmit = async (values) => {
    try {
      const res = await registerUser(values);
      if ("data" in res) {
        const { data } = res;
        if (data.status === true) {
          dispatch(register({ token: data.token, user: null }));
          toast.success(data?.message, successProps);
          //console.log(data);
          await login.onSubmit(
            values,
            `/settings/profileedit`
          );
        }
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else if (error.response.status === 401) {
        toast.error("Wrong credentials", errorProps);
      } else {
        toast.error("Login failed", errorProps);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      account_type: "",
      confirm_password: "",
      license: null,
      division: null,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .min(6, "Fullname must at least be 6 chars, first and last name")
        .max(20, "Fullname must be less than or equal to 20")
        .required("Required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
          "Password must contain at least one lowercase letter, one uppercase letter, and one number"
        )
        .required("Required"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      phone: Yup.number().required("Phone number is required"),
      email: Yup.string()
        .email("Provide vaild email address")
        .required("Required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return { formik, loading };
};

export default useSignUp;

import React, { useState } from "react";
import "./JobDetails.css";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import { CiLocationOn } from "react-icons/ci";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { RxMagicWand } from "react-icons/rx";
import { BsBookmark } from "react-icons/bs";
import { VscCompass } from "react-icons/vsc";
import { useTypedSelector } from "../../hook/useTypedSelector";
import { Bars } from "react-loader-spinner";
import CustomButton from "../../components/customButton/CustomButton";
import { HiMiniLockClosed } from "react-icons/hi2";
import { FaUsers } from "react-icons/fa6";
import { _currency_format } from "../../utils";
import { FaLockOpen } from "react-icons/fa6";
import {
  useApplyJobMutation,
  useCheckStatusQuery,
  useGetSingleJobQuery,
  useSaveJobMutation,
  useToggleJobMutation,
} from "../../store/query/jobs";
import { toast } from "react-toastify";

const JobDetails = () => {
  const navigate = useNavigate();
  const { id, posted } = useParams();
  const { user } = useTypedSelector((store) => store.appUser);
  const {
    data: job,
    isLoading,
    refetch: refetchJob,
  } = useGetSingleJobQuery({ id });
  const [filterApply, setFilterApply] = useState({
    type: "applied",
    id: job?.data?.id ?? id,
  });
  const { data: applied, isLoading: isCheckingApplied } =
    useCheckStatusQuery(filterApply);

  const [filterSave, setFilterSave] = useState({
    type: "saved",
    id: job?.data?.id ?? id,
  });
  const { data: saved, isLoading: isCheckingSaved } =
    useCheckStatusQuery(filterSave);
  const [applyJob, { isLoading: isApplying }] = useApplyJobMutation();
  const [saveJob, { isLoading: isSaving }] = useSaveJobMutation();
  const [toggleJob, { isLoading: isTogglingJob }] = useToggleJobMutation();

  const handleApplyJob = async (id) => {
    const res = await applyJob({ job_id: id });
    if (
      res?.data?.status &&
      res?.data?.message === "Job Application Created Successfully"
    ) {
      setFilterApply({
        type: "applied",
        id: job?.data?.id,
      });
      toast.success(res?.data?.message);
    } else {
      toast.error("Failed to Apply Job!!");
    }
  };

  const handleToggleJob = async (status) => {
    const payload = {
      id,
      status,
      type: user.account_type,
      _method: "PATCH",
    };
    const res = await toggleJob(payload);
    if (res?.data?.status) {
      toast.success(res?.data?.message);
      refetchJob();
    } else {
      toast.error("Please try again later!!!");
    }
  };

  const handleSaveJob = async (id) => {
    const res = await saveJob({ job_id: id });
    if (
      res?.data?.status &&
      res?.data?.message === "Job was saved Successfully"
    ) {
      setFilterSave({
        type: "saved",
        id: job?.data?.id,
      });
      toast.success(res?.data?.message);
    } else {
      toast.error("Failed to Save Job!!");
    }
  };

  const btnStyled = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "8px 25px",
  };

  const jobOwner =
    Number(job?.data?.user_id) === user?.id &&
    job?.data?.account_type === user?.account_type;

  return (
    <>
      <Navbar />

      {isLoading ? (
        <div className="loading-screen-jobs">
          <Bars
            height="80"
            width="80"
            color=" #245293"
            ariaLabel="bars-loading"
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="jobDetails-container">
          <div className="jobDetails-top">
            <div className="jobDetails-desc">
              <h3>{job?.data?.title}</h3>
              <div className="overall-special">
                <div className="specialty between">
                  <div className="specialty">
                    <RxMagicWand className="jobDetails-icon" />
                    <span>Division</span>
                  </div>
                  <span className="special-text">{job?.data?.division}</span>
                </div>
                <div className="specialty between">
                  <div className="specialty">
                    <CiLocationOn className="jobDetails-icon" />
                    <span>Location</span>
                  </div>
                  <span className="special-text">
                    {job?.data?.city}, {job?.data?.state}
                  </span>
                </div>

                <div className="specialty between">
                  <div className="specialty">
                    <VscCompass className="jobDetails-icon" />
                    <span>Hourly Pay Rate</span>
                  </div>
                  <span className="special-text">
                    {_currency_format(Number(job?.data?.hourly_rate), "NGN")}
                  </span>
                </div>

                {job?.data?.applicants > 0 ||
                user?.account_type === "organisation" ? (
                  <div className="specialty between">
                    <div className="specialty">
                      <FaUsers className="jobDetails-icon" />
                      <span>Applicants</span>
                    </div>
                    <span className="special-text">
                      {job?.data?.applicants}
                    </span>
                  </div>
                ) : (
                  <span style={{ color: "#245293", fontWeight: 800 }}>
                    Be the first to apply for this job
                  </span>
                )}
              </div>
            </div>

            {posted && jobOwner ? (
              <div className="jobDetails-apply">
                <div className="findJobs-button">
                  <CustomButton
                    onClick={() => navigate(`/postjob/${id}`)}
                    disable={job?.data?.is_editable === 0}
                    title={
                      <div style={btnStyled}>
                        Edit <CiEdit />
                      </div>
                    }
                  />
                </div>
                <div className="findJobs-button">
                  <CustomButton
                    className="bookmark"
                    variant="outline"
                    onClick={() => {
                      handleToggleJob(job?.data?.status === "1" ? 0 : 1);
                    }}
                    loading={isTogglingJob}
                    title={
                      job?.data?.status === "1" ? (
                        <div style={btnStyled}>
                          Close <HiMiniLockClosed />
                        </div>
                      ) : (
                        <div style={btnStyled}>
                          Open <FaLockOpen />
                        </div>
                      )
                    }
                  />
                </div>
              </div>
            ) : user?.account_type === "individual" && !jobOwner ? (
              <div className="jobDetails-apply">
                <div className="findJobs-button">
                  <CustomButton
                    disable={isCheckingApplied || applied?.status}
                    onClick={() => {
                      handleApplyJob(job?.data?.id);
                    }}
                    title={
                      <>
                        {applied?.status ? (
                          <div style={btnStyled}>
                            Applied <FaRegArrowAltCircleRight />
                          </div>
                        ) : (
                          <div style={btnStyled}>
                            Apply Now <FaRegArrowAltCircleRight />
                          </div>
                        )}
                      </>
                    }
                    loading={isApplying}
                  />
                </div>
                <div className="findJobs-button">
                  <CustomButton
                    variant="outline"
                    disable={isCheckingSaved || saved?.status}
                    onClick={() => {
                      handleSaveJob(job?.data?.id);
                    }}
                    title={
                      <>
                        {saved?.status ? (
                          <div style={btnStyled}>
                            Saved <BsBookmark />
                          </div>
                        ) : (
                          <div style={btnStyled}>
                            Save For Later <BsBookmark />
                          </div>
                        )}
                      </>
                    }
                    loading={isSaving}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="jobDetails-bottom">
            <div className="jobDetails-requirements">
              <p>{job?.data?.description}</p>

              <div className="requirements">
                <h3>Requirements</h3>
                <ul>
                  {job?.data?.requirements?.map((req, i) => (
                    <li key={i}>{req}</li>
                  ))}
                </ul>
              </div>

              <div className="requirements">
                <h3>Responsibilities</h3>
                <ul>
                  {job?.data?.responsibilities?.map((res, i) => (
                    <li key={i}>{res}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {job?.data?.timesheet?.length > 0 && (
            <div className="candidates-result">
              <h3>Timesheet</h3>

              <div className="candidates-result-headings">
                <span>Start Date</span>
                <span>End Date</span>
                <span>Start Time</span>
                <span>End Time</span>
                <span>Hours</span>
                <span>Charges</span>
              </div>
              <div className="candidates-result-box">
                {job?.data?.timesheet?.map((item, i) => (
                  <div key={i} className="single-candidate">
                    <div className="single">
                      <span className="mobile-time">Start Date:</span>
                      <span>{item.start_date}</span>
                    </div>
                    <div className="single">
                      <span className="mobile-time">End Date:</span>
                      <span>{item.end_date}</span>
                    </div>
                    <div className="single">
                      <span className="mobile-time">Start Time:</span>
                      <span>{item.start_time}</span>
                    </div>
                    <div className="single">
                      <span className="mobile-time">End Time:</span>
                      <span>{item.end_time}</span>
                    </div>
                    <div className="single">
                      <span className="mobile-time">Hours:</span>
                      <span>{item.hour}</span>
                    </div>
                    <div className="single">
                      <span className="mobile-time">Charges:</span>
                      <span>
                        {_currency_format(Number(item.charge), "NGN")}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default JobDetails;

import React from "react";
import "./DashboardModal.css";

const DashboardModal = ({ children, isOpen, onClose, height, customStyle }) => {
  return (
    isOpen && (
      <div
        className="dashboard-modal-box"
        onClick={onClose}
      >
        <div style={{ height, ...customStyle }} className="dashboard-modal-content">
          {children}
        </div>
      </div>
    )
  );
};

export default DashboardModal;

import React, { Suspense } from "react";
import "./App.css";
import MainRouter from "./components/mainRouter/MainRouter";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FullLoader from "./components/fullLoader/FullLoader";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  return (
    <div className="app">
      <Suspense fallback={<FullLoader />}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <MainRouter />
              <ToastContainer />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </Suspense>
    </div>
  );
}

export default App;

import React from "react";

const CancelIcon = ({onClose}) => {
  return (
    <div style={{cursor: 'pointer'}} onClick={onClose}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M12.6005 13.6538L13.6543 12.6L8.0543 6.99999L13.6543 1.39999L12.6005 0.346161L7.00047 5.94616L1.40047 0.346161L0.346646 1.39999L5.94665 6.99999L0.346646 12.6L1.40047 13.6538L7.00047 8.05381L12.6005 13.6538Z"
          fill="#1C1B1F"
        />
      </svg>
    </div>
  );
};

export default CancelIcon;

import { useRef, useState } from "react";
import { FaBars, FaTimes, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Logo from "../../assets/Group.png";
import SearchBox from "../../components/searchBox/SearchBox";
import { useTypedSelector } from "../../hook/useTypedSelector";
import useLogout from "../../hook/useLogout";
import CustomButton from "../customButton/CustomButton";

function Navbar({ showSearch, filter }) {
  const [openSearch, setOpenSearch] = useState(false);
  const { user } = useTypedSelector((store) => store.appUser);
  const navRef = useRef();
  const logOut = useLogout();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <>
      <div className="nav-container">
        <header>
          <Link to="/" className="nav_title">
            <img src={Logo} alt="logo-freelancemedic" />
          </Link>

          <nav ref={navRef}>
            <Link className="homee" to="/joblistings">
              Job Listings
            </Link>
            <Link to={user ? "/postjob" : "/login"}>Post a Job</Link>
            <Link to="/about">About Us</Link>
            <Link to="/contact">Contact Us</Link>
            {user && <Link to="/dashboard">Dashboard</Link>}

            {user ? (
              <Link className="login-btn-link" onClick={() => logOut()}>
                <CustomButton
                  customStyles={{
                    width: "77px",
                    height: "33px",
                    marginTop: "0",
                    fontSize: "14px",
                    borderRadius: "5px",
                  }}
                  title="Logout"
                />
              </Link>
            ) : (
              <Link to="/login" className="login-btn-link">
                <CustomButton
                  customStyles={{
                    width: "77px",
                    height: "33px",
                    marginTop: "0",
                    fontSize: "14px",
                    borderRadius: "5px",
                  }}
                  title="Login"
                />
              </Link>
            )}
            <button className="nav-btn nav-close-btn" onClick={showNavbar}>
              <FaTimes />
            </button>
          </nav>

          <div className="mobile-nav">
            {showSearch && (
              <button className="nav-search-btn">
                <FaSearch onClick={() => setOpenSearch(true)} />
              </button>
            )}

            <button className="nav-btn" onClick={showNavbar}>
              <FaBars />
            </button>
          </div>
        </header>
      </div>
      <SearchBox
        isOpen={openSearch}
        onClose={() => setOpenSearch(false)}
        filter={filter}
      />
    </>
  );
}

export default Navbar;

import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useForgetPasswordMutation } from "../store/query/auth";

const useForgetPassword = () => {
  const [forgetPassword, { isLoading }] = useForgetPasswordMutation();

  const handleSubmit = async (values) => {
    const res = await forgetPassword(values);
    if (res?.data?.status) {
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Provide vaild email address")
        .required("Required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return { ...formik, isLoading };
};

export default useForgetPassword;

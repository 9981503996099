import React from "react";
import "./About.css";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import Goals from "../../components/goals/Goals";
import JoinUs from "../../components/join/JoinUs";
import AboutWhy from "../../components/aboutwhy/AboutWhy";

const About = () => {
  return (
    <div className="app-container">
      <Navbar showSearch />

      <div className="about-main">
        <div className="about-main-content">
          <h1 data-aos="zoom-in">ABOUT US</h1>
        </div>
      </div>

      <AboutWhy />

      <Goals />

      <JoinUs />

      <Footer />
    </div>
  );
};

export default About;

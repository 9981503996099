import { api } from "../BaseConfig";

export const testimonialSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getTestimonials: builder.query({
      query: () => ({
        url: `/testimonials`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetTestimonialsQuery } = testimonialSlice;

import React, { memo } from "react";

const CustomInput = ({
  label,
  placeholder,
  type,
  error,
  value,
  onChange,
  touched,
  endIcon,
  id,
  onFocus,
  onBlur
}) => {
  const styles = {
    cover: {
      display: "flex",
      flexDirection: "column",
      gap: 5,
    },
    bgInput: {
      width: "100%",
      position: 'relative'
    },
    input: {
      width: "100%",
      padding: "0.5rem",
      borderRadius: "20px",
      border: error && touched ? "1px solid red" : "1px solid rgb(236, 236, 236)",
      paddingLeft: "15px",
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 500,
      color: "black",
      outline: "none",
    },
    error: {
      fontSize: "10px",
      color: "red",
      paddingLeft: "15px",
    }
  };

  return (
    <div style={styles.cover}>
      {label && (
        <h4 style={{ fontSize: "14px", fontWeight: 500, lineHeight: "24px" }}>
          {label}
        </h4>
      )}
      <div style={styles.bgInput}>
        <input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={styles.input}
          type={type}
          placeholder={placeholder}
          id={id}
          onBlur={onBlur}
        />
        {endIcon && endIcon}
        {touched && error && <span style={styles.error}>{error}</span>}
      </div>
    </div>
  );
};

export default memo(CustomInput);

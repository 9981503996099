import React from "react";
import { BiBriefcase } from "react-icons/bi"
import './styles/BoxAnalysis.css'

const BoxAnalysis = ({num, title, icon, bg}) => {
  return (
    <div style={{backgroundColor: bg}} className="box-analysis">
      <div className="box-analysis-left">
        <h4>{num}</h4>
        <span>{title}</span>
      </div>
      <div className="box-analysis-right">
        {icon}
      </div>
    </div>
  );
};

export default BoxAnalysis;

import React, { useCallback, useState } from "react";
import "./LicensePage.css";
import Logo from "../../../assets/fmlogo-login.png";
import Doctor from "../../../assets/doc-2.png";
import whiteBox from "../../../assets/white-box.png";
import { Link } from "react-router-dom";
import DownloadIcon from "../../../components/icon/DownloadIcon";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import { errorProps } from "../../../utils";
import CustomButton from "../../../components/customButton/CustomButton";
import CustomDialog from "../../../components/customDialog/CustomDialog";
import usePublicDivision from "../../../hook/usePublicDivision";
import CustomAutoComplete from "../../../components/customAutoComplete/CustomAutoComplete";

const LicensePage = ({ formik, loading }) => {
  const [license, setLicense] = useState(null);
  const [divisionCase, setDivisionCase] = useState(null);
  const [showLastStep, setShowLastStep] = useState(false);
  const { divisonList } = usePublicDivision();
  const onDrop = useCallback((files) => {
    const file = files[0];
    const isAllow = file.type === "image/jpeg" || file.type === "image/png";
    const fileSizeInKB = file?.size / 1024;
    const fileSizeInMB = fileSizeInKB / 1024;

    const sizeAllowed = 1 >= fileSizeInMB;

    if (isAllow) {
      if (sizeAllowed) {
        setLicense(
          ...files.map((file) =>
            Object.assign(file, { preview: URL.createObjectURL(file) })
          )
        );
      } else {
        toast.error("License should not be more than 1MB", errorProps);
      }
    } else {
      toast.error("License should be in PNG or JPEG", errorProps);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="license-container">
      <div className="license-left">
        <div className="license-left-content">
          {/* logo */}
          <Link to="/">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
          </Link>
          {/* logo */}

          <div className="license-left-text">
            <h3>
              Embrace flexible and rewarding practice through healthcare locum
            </h3>
            <p>
              Join the largest community of healthcare professionals on locum
              staffing in Africa as a Healthcare professional; and kick-start a
              flexible and rewarding practice.
            </p>
          </div>
          <div className="license-left-image">
            <img className="white-box" src={whiteBox} alt="" />
            <img className="doc" src={Doctor} alt="" />
          </div>
        </div>
      </div>

      <div className="license-right">
        <div className="license-right-body">
          <div className="license-right-content">
            <h2>
              Upload a copy of <br /> your practice license
            </h2>
            <p className="head-desc">
              We need to verify that you are eligible to use this platform.
              <br />
              Note: your pratice license is NOT disclosed to any third party.
            </p>

            <div
              className="bg-file-license"
              {...getRootProps()}
              style={
                license
                  ? { border: "dashed 4px #245293" }
                  : { border: "dashed 4px #808080" }
              }
            >
              <input type="files" {...getInputProps()} />
              {license ? (
                <div className="license-img-cover">
                  <img src={license.preview} alt="license-file" />
                  <div className="upload-btn-bg">
                    <button>Upload a new file</button>
                  </div>
                </div>
              ) : (
                <div className="inner-bg-file-license">
                  <DownloadIcon />

                  <h4>Upload or drop your clear image here</h4>
                  <p>PNG, JPEG up to 10MB.</p>

                  <button>Choose a file</button>
                </div>
              )}
            </div>

            <div className="bg-license">
              <CustomButton
                disable={!license}
                title="Continue"
                onClick={() => {
                  setShowLastStep(true);
                }}
              />
            </div>

            <div className="skip-bg">
              <span
                className="skip-btn"
                onClick={() => {
                  formik.setFieldValue("license", null);
                  formik.setFieldValue("division", null);
                  formik.setFieldValue("account_type", "organisation");
                  formik.handleSubmit();
                }}
              >
                Skip
              </span>
              <span className="skip-text"> for Hiring Manager</span>
            </div>
          </div>
        </div>
      </div>

      <CustomDialog onClose={() => setShowLastStep(false)} open={showLastStep}>
        <div className="modal-division-bg">
          <div className="modal-division-bg-header">
            <h4>Select Division</h4>
          </div>

          <div className="modal-division-bg-body">
            <div className="auto-complete-con">
              <label className="division-label">Division</label>
              <CustomAutoComplete
                id="division"
                isClearable={false}
                placeholder={"Select division"}
                options={divisonList}
                customStyles={{
                  control: {
                    width: "100%",
                    borderRadius: "5px",
                    border: "1px solid #000",
                  },
                }}
                onChange={(e) => {
                  setDivisionCase(e);
                }}
                menuHeight={100}
              />
            </div>
          </div>

          <div className="modal-division-bg-footer">
            <CustomButton
              title="Cancel"
              customStyles={{
                width: "30%",
                borderRadius: "5px",
              }}
              background="red"
              border="red"
              onClick={() => setShowLastStep(false)}
            />

            <CustomButton
              title="Finish"
              type="submit"
              loading={loading}
              customStyles={{ width: "30%", borderRadius: "5px" }}
              onClick={() => {
                if (license) {
                  formik.setFieldValue("license", license);
                  formik.setFieldValue("division", divisionCase?.value);
                  formik.setFieldValue("account_type", "individual");
                  formik.handleSubmit();
                } else {
                  toast.warn(
                    "Please add your license file to continue as an individual or click on skip to register as an organisation",
                    errorProps
                  );
                }
              }}
            />
          </div>
        </div>
      </CustomDialog>
      <ToastContainer />
    </div>
  );
};

export default LicensePage;

import React from "react";
import "./PasswordPage.css";

const PasswordPage = () => {
  return (
    <div className="password-page-container">
      <h6>Change Password</h6>
      <div className="password-page-details">
        <p className="heading-first">
          Please create your password that is atleast 8 characters long
        </p>
        <div className="inner-contain">
          <h4>Current Password*</h4>
          <input type="password" />
          <h4>New Password*</h4>
          <input type="password" />
          <h4>Confirm New Password*</h4>
          <input type="password" />

          <div className="button-container">
            <button className="btn-save">Save password</button>
            <button className="btn-forgot">Forgot password</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordPage;

import React, { memo } from "react";
import CancelIcon from "../../../../../components/icon/CancelIcon";
import BackIcon from "../../../../../components/icon/BackIcon";
import "./ModalHeader.css";

const ModalHeader = ({ onBack, title, onClose }) => {
  return (
    <div className="header-payment-modal">
      {onBack ? (
        <div onClick={onBack}>
          <BackIcon />
        </div>
      ) : (
        <div />
      )}
      <h3 style={onBack ? {} : { marginLeft: 20 }}>{title}</h3>
      {onClose && <CancelIcon onClose={onClose} />}
    </div>
  );
};

export default memo(ModalHeader);

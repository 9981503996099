import { api } from "../BaseConfig";

export const jobSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query({
      query: (params) => ({
        url: `/jobs`,
        method: "GET",
        params: params,
      }),
    }),
    createJob: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        const keys = [
          "company_name",
          "contact_email",
          "phone",
          "nationality",
          "title",
          "description",
          "address",
          "hourly_rate",
          "division_id",
          "state_id",
          "city_id",
        ];
        keys.forEach((key) => {
          if (data[key] !== undefined && data[key] !== null) {
            formData.append(key, data[key]);
          }
        });
        if (Array.isArray(data.responsibilities)) {
          data.responsibilities.forEach((res) => {
            formData.append("responsibilities[]", res);
          });
        }

        if (Array.isArray(data.requirements)) {
          data.requirements.forEach((req) => {
            formData.append("requirements[]", req);
          });
        }

        const timesheetArray = Array.isArray(data.timesheet)
          ? data.timesheet
          : [data.timesheet];

        timesheetArray.forEach((item, index) => {
          Object.keys(item).forEach((key) => {
            formData.append(`timesheet[${index}][${key}]`, item[key]);
          });
        });

        return {
          url: `/${data.type}/add-job`,
          method: "POST",
          body: formData,
        };
      },
    }),
    updateJob: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        const keys = [
          "company_name",
          "contact_email",
          "phone",
          "nationality",
          "title",
          "description",
          "address",
          "hourly_rate",
          "division_id",
          "state_id",
          "city_id",
          "_method",
        ];
        keys.forEach((key) => {
          if (data[key] !== undefined && data[key] !== null) {
            formData.append(key, data[key]);
          }
        });
        if (Array.isArray(data.responsibilities)) {
          data.responsibilities.forEach((res) => {
            formData.append("responsibilities[]", res);
          });
        }

        if (Array.isArray(data.requirements)) {
          data.requirements.forEach((req) => {
            formData.append("requirements[]", req);
          });
        }

        const timesheetArray = Array.isArray(data.timesheet)
          ? data.timesheet
          : [data.timesheet];

        timesheetArray.forEach((item, index) => {
          Object.keys(item).forEach((key) => {
            formData.append(`timesheet[${index}][${key}]`, item[key]);
          });
        });

        return {
          url: `/${data.type}/update-job/${data.id}`,
          method: "POST",
          body: formData,
        };
      },
    }),
    getSingleJob: builder.query({
      query: (params) => ({
        url: `/jobs/${params.id}`,
        method: "GET",
      }),
    }),
    checkStatus: builder.query({
      query: (params) => ({
        url: `/individual/${params.type}-job-status/${params.id}`,
        method: "GET",
      }),
    }),
    applyJob: builder.mutation({
      query: (data) => {
        return {
          url: `/individual/add-application`,
          method: "POST",
          body: data,
        };
      },
    }),
    saveJob: builder.mutation({
      query: (data) => {
        return {
          url: `/individual/save-job`,
          method: "POST",
          body: data,
        };
      },
    }),
    getPostedJobs: builder.query({
      query: (params) => ({
        url: `/${params.type}/view-job`,
        method: "GET",
        params,
      }),
    }),
    toggleJob: builder.mutation({
      query: (data) => {
        return {
          url: `/${data.type}/update-job-status/${data.id}`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetJobsQuery,
  useCreateJobMutation,
  useUpdateJobMutation,
  useGetSingleJobQuery,
  useCheckStatusQuery,
  useApplyJobMutation,
  useSaveJobMutation,
  useGetPostedJobsQuery,
  useToggleJobMutation,
} = jobSlice;

import React from "react";
import "./Home.css";
import Navbar from "../../components/navbar/Navbar";
import { CiSearch, CiLocationOn } from "react-icons/ci";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import people from "../../assets/people.png";
import Explore1 from "../../components/explore/Explore";
import FindJobs from "../../components/findJobs/FindJobs";
import ExploreDivisions from "../../components/exploreDivisions/ExploreDivisions";
import ExploreEmployees from "../../components/exploreEmployees/ExploreEmployees";
import ClientSlider from "../../components/clientSlider/ClientSlider";
import Footer from "../../components/footer/Footer";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="app-container">
      <Navbar showSearch />

      <div className="home-container">
        <div data-aos="fade-in" className="home-box-responsive">
          <div className="home-box-one">
            <div className="home-box-one-content">
              <h2 data-aos="fade-right">
                Optimizing On-demand Healthcare Staffing.
              </h2>
              <p data-aos="fade-left">
                The right place is here, when staffing is urgent and decisive!
                Built for Healthcare professionals and organisations.
              </p>
              <div
                className="home-box-one-search-box"
                onClick={() => navigate("/joblistings")}
                data-aos="fade-right"
              >
                {/* job title search input */}
                <div className="input-1">
                  <CiSearch />
                  <input
                    type="text"
                    placeholder="Job Title"
                  />
                </div>
                {/* job title search input */}

                {/* location search input */}
                <div className="input-1">
                  <CiLocationOn />
                  <input
                    type="text"
                    placeholder="Location"
                  />
                </div>
                {/* location search input */}

                <Link
                  to="/joblistings"
                  onClick={() => {}}
                  className="home-box-one-button"
                >
                  <button>
                    Find Jobs <FaRegArrowAltCircleRight />
                  </button>
                </Link>
              </div>
              <div data-aos="fade-left" className="community-box">
                <div className="community-box-image">
                  <img src={people} alt="people" />
                </div>
                <p>
                  Diverse professional workgroups of healthcare practitioners.
                </p>
              </div>
            </div>
          </div>

          {/* <div className="scope-img-1">
            <img src={scope} alt="scope" />
          </div>

          <div className="scope-img-2">
            <img src={pills} alt="pills" />
          </div> */}
        </div>

        {/* feature-box */}

        {/* how it works */}
        <Explore1 />
        {/* how it works */}

        {/* locum jobs */}
        <FindJobs />
        {/* locums jobs */}

        {/* explore */}
        <ExploreDivisions />
        {/* explore */}

        {/* explore employee */}
        <ExploreEmployees title="Wish to partner with us?" input={true} />
        {/* explore employee */}

        {/* client slider */}
        <ClientSlider />
        {/* client slider */}

        <hr />

        {/* footer */}
        <Footer />
        {/* footer */}
      </div>
    </div>
  );
};

export default Home;

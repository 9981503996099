import { getCodes, getName } from "country-list";
import React, { useMemo } from "react";
import ReactCountryFlag from "react-country-flag";
import { getCountryData } from "countries-list";

function useCountries() {
  const countries = useMemo(() => {
    return getCodes()
      .map((code) => ({
        code,
        value: code,
        name: getName(code),
        label: getName(code),
        continent: getCountryData(code),
        startAdornment: <ReactCountryFlag countryCode={code} svg />,
      }))
      ?.sort((a, b) => a?.label?.localeCompare(b?.label));
  }, []);

  return countries.filter((country) => country.code === "NG");
}

export default useCountries;

// for african countries

//countries.filter((country) => country.continent.continent === "AF");
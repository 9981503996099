import React from "react";
import "./JobSummary.css";
import ApplicantsIcon from "../../components/icon/ApplicantsIcon";
import ViewsIcon from "../../components/icon/ViewsIcon";

const JobSummary = () => {
  return (
    <div className="jobs-diagram">
      <p>Locum Pharmacist</p>
      <div className="broad">
        <div className="apply" />
        <div className="view" />
      </div>

      <div className="below-apply">
        <div className="each-apply">
          <p>Applicants</p>
          <div className="icon-apply">
            <ApplicantsIcon />
            <p>200</p>
          </div>
        </div>

        <div className="each-apply">
          <p>Views</p>
          <div className="icon-apply">
            <ViewsIcon />
            <p>400</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSummary;

import React from "react";
import "./JoinUs.css";
import people from "../../assets/people.png";
import { BsArrowRight } from "react-icons/bs";

const JoinUs = ({}) => {
  return (
    <div className="join-body" data-aos="zoom-in-up">
      <div className="join-container">
        <div className="box">
          <h2>Wish to partner with us?</h2>

          <p>
            With our team of innovative healthcare practitioners and IT
            personnels, we could offer a solution tailored to your heathcare
            facility/organization workforce needs. Kindly enter your email below to get started.
          </p>

          <div className="join-input-email">
            <input placeholder="Enter email" />
            <button>
              Get Started <BsArrowRight />
            </button>
          </div>

          <div className="people">
            <div>
              <img src={people} alt="" />
            </div>
            <span>Join a communty of over 10k healthcare practitioners</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;

import { useMemo } from "react";
import { useGetOrgRolesQuery } from "../store/query/utility";

const useOrganisationRole = () => {
  const { data: orgList } = useGetOrgRolesQuery();

  const roles = useMemo(
    () =>
      orgList?.data?.allOrganisationRole?.map((i) => {
        return { label: i.name, value: i.id };
      }),
    [orgList?.data]
  );

  return { roles };
};

export default useOrganisationRole;

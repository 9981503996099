import React from "react";
import "./SearchBox.css";
import { ImCancelCircle } from "react-icons/im";
import { Link } from "react-router-dom";
import { CiSearch, CiLocationOn } from "react-icons/ci";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import Slide from "react-reveal/Bounce";
import { Autocomplete } from "@mui/material";

const LogBox = ({ isOpen, onClose, filter }) => {
  // const [location, setLocation] = useState("");
  // const [jobTitle, setJobTitle] = useState("");
  const top100Films = [];
  
  return (
    isOpen && (
      <Slide top duration={1500}>
        <div className="search-modal-container">
          <div className="home-box-one-search-box">
            
            <div className="input-1">
              <CiSearch />
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                sx={{ width: "270px", height: "20px" }}
                options={top100Films.map((option) => option.title)}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      placeholder="Job Title"
                      type="text"
                      {...params.inputProps}
                      style={{ backgroundColor: "transparent" }}
                    />
                  </div>
                )}
              />
            </div>
            <div className="input-1">
              <CiLocationOn />
              <input
                type="text"
                placeholder="Location"
                onChange={(e) => {}}
              />
            </div>

            <Link
              to="/joblistings"
              onClick={() => {}}
              className="home-box-one-button"
            >
              <button>
                Find Jobs <FaRegArrowAltCircleRight />
              </button>
            </Link>
          </div>
          <div className="close-container">
            <ImCancelCircle
              className="cancel-close-searchbox"
              onClick={onClose}
            />
          </div>
        </div>
      </Slide>
    )
  );
};

export default LogBox;

import { api } from "../BaseConfig";

export const paymentsSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getBanks: builder.query({
      query: () => ({
        url: `/banks`,
        method: "GET",
      }),
    }),
    getAccount: builder.query({
      query: ({ acc_type }) => ({
        url: `/${acc_type}/view-account`,
        method: "GET",
      }),
    }),
    verifyAccount: builder.query({
      query: ({ acc_no, bank_code }) => ({
        url: `/verify?account_number=${acc_no}&bank_code=${bank_code}`,
        method: "GET",
      }),
    }),
    addBankAccount: builder.mutation({
      query: (data) => {
        return {
          url: `/${data.acc_type}/add-account`,
          method: "POST",
          body: data,
        };
      },
    }),
    updateBankAccount: builder.mutation({
      query: (data) => {
        return {
          url: `/${data.acc_type}/update-account`,
          method: "POST",
          body: data,
        };
      },
    }),
    fetchPaymentChannel: builder.mutation({
      query: (data) => ({
        url: `/${data.acc_type}/payment-channel`,
        method: "GET",
      }),
    }),
    makePayment: builder.mutation({
      query: (data) => {
        return {
          url: `/${data.acc_type}/make-payment`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetBanksQuery,
  useGetAccountQuery,
  useLazyVerifyAccountQuery,
  useAddBankAccountMutation,
  useUpdateBankAccountMutation,
  useFetchPaymentChannelMutation,
  useMakePaymentMutation,
} = paymentsSlice;

import React from "react";
import JobItem from "../jobItem/JobItem";
import { Link } from "react-router-dom";
import { useGetJobsQuery } from "../../store/query/jobs";

const AllJobsItem = () => {
  const { data: jobs } = useGetJobsQuery({});

  return (
    <div>
      <div className="proxy-job-container">
        {jobs?.data?.allJob?.data?.length === 0 ? (
          <h2 className="no-jobs-found"> No Jobs Found! </h2>
        ) : (
          <div className="job-result-container">
            {jobs?.data?.allJob?.data?.slice(0, 3).map((job) => {
              return (
                <Link to={`/joblistings/${job.id}`} key={job.id}>
                  <div className="findJobs-jobitem">
                    <JobItem
                      title={job.title}
                      rate={job.hourly_rate}
                      jobLoc={`${job.city}, ${job.state}`}
                      jobDesc={`${job.description.slice(0, 100)} ...`}
                      jobOrg={job.company_name}
                      jobImg={job.logo}
                      count={500}
                    />
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>

      {jobs?.data?.allJob?.data?.length && (
        <div className="button-full">
          <Link className="job-view-more" to="/joblistings">
            View More
          </Link>
        </div>
      )}
    </div>
  );
};

export default AllJobsItem;

import React from "react";

const DownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g mask="url(#mask0_1108_9122)">
        <path
          d="M7.11537 19C6.65512 19 6.27083 18.8458 5.9625 18.5375C5.65417 18.2291 5.5 17.8448 5.5 17.3846V15H6.5V17.3846C6.5 17.5384 6.5641 17.6794 6.6923 17.8077C6.82052 17.9359 6.96154 18 7.11537 18H17.8846C18.0385 18 18.1795 17.9359 18.3077 17.8077C18.4359 17.6794 18.5 17.5384 18.5 17.3846V15H19.5V17.3846C19.5 17.8448 19.3458 18.2291 19.0375 18.5375C18.7292 18.8458 18.3449 19 17.8846 19H7.11537ZM12 15.2307V6.58073L9.66923 8.91151L8.96152 8.19228L12.5 4.65381L16.0385 8.19228L15.3308 8.91151L13 6.58073V15.2307H12Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default DownloadIcon;

import React, { memo } from "react";
import { Outlet } from "react-router-dom";
import DashboardDrawer from "../../components/dashboardDrawer/DashboardDrawer";
import "./RegularDashboard.css";
import DashboardNavbarMobile from "../../components/dashboardNavbarMobile/DashboardNavbarMobile";
import DashboardHeadIcon from "../../components/dashboardHeadIcon/DashboardHeadIcon";
import { SiOnlyoffice } from "react-icons/si";
import { AiOutlineHome, AiOutlineSetting } from "react-icons/ai";
import { ImList2 } from "react-icons/im";
import { BsWallet2 } from "react-icons/bs";
import { PiTimer } from "react-icons/pi";
import { MdOutlineEditCalendar } from "react-icons/md";
import { LuLogOut } from "react-icons/lu";
import { useTypedSelector } from "../../hook/useTypedSelector";

const RegularDashboard = () => {
  const { user } = useTypedSelector((store) => store.appUser);

  const navList = [
    {
      name: "Dashboard",
      to: "/dashboard",
      icon: <AiOutlineHome />,
      hide: false,
    },
    {
      name: "Job Listing",
      to: "/shortlist",
      icon: <ImList2 />,
      hide: false,
    },
    {
      name: "Job Activity",
      to: "/activity",
      icon: <PiTimer />,
      hide: user?.account_type === "organisation",
    },
    {
      name: "Post Job",
      to: "/postjob",
      icon: <SiOnlyoffice />,
      hide: user?.account_type === "individual",
    },
    {
      name: "Payment",
      to: "/payment",
      icon: <BsWallet2 />,
      hide: false,
    },
    // {
    //   name: "Messages",
    //   to: "/messages",
    //   icon: <GrMailOption />,
    // },
    {
      name: "Locum Manager",
      to: "/locummanager",
      icon: <MdOutlineEditCalendar />,
      hide: false,
    },
    {
      name: "Settings",
      to: "/settings",
      icon: <AiOutlineSetting />,
      hide: false,
    },
    {
      name: "Logout",
      to: "/",
      icon: <LuLogOut />,
      hide: false,
    },
  ];

  return (
    <>
      <div className="dash-desktop">
        <DashboardDrawer navList={navList} />
      </div>
      <div className="dash-mobile">
        <DashboardNavbarMobile navList={navList} />
      </div>
      <div className="regular-dashboard">
        <div className="inner">
          <div className="right-icons">
            <DashboardHeadIcon />
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default memo(RegularDashboard);
